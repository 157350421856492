import { Component, EventEmitter, Output } from '@angular/core';
import { QuoteSummaryComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-quote-summary',
   templateUrl: 'quote-summary.component.html',
   styleUrls: ['quote-summary.component.scss'],
   providers: [{ provide: QuoteSummaryComponentBase, useExisting: QuoteSummaryComponent }]
})
export class QuoteSummaryComponent extends QuoteSummaryComponentBase
{
   @Output()
   public readonly quoteCleared = new EventEmitter();
}
