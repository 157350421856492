import { ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { LocationItem, MapMode, StoreMapProps, StorePlace, StorePlaceUtil } from "../../models/store-map";
import { StoreMapService } from "../../services/store-map.service";
import { MonitoringService } from "tutch-kiosk-core";

const COMPONENT_NAME = 'MapViewerComponent';

@Component({
   selector: 'app-map-viewer',
   templateUrl: 'map-viewer.component.html',
   styleUrls: ['map-viewer.component.scss']
})
export class MapViewerComponent implements OnDestroy
{
   //#region - Fields/Properties
   //================================================================================
   @ViewChild('htmlFrame')
   private _htmlFrame!: ElementRef<HTMLIFrameElement>;
   //================================================================================
   private _initializationPromiseResolver: (result: boolean) => void;
   private _deviceStoreNumber: string;
   private _deviceStoreStartAddress: string;
   //================================================================================
   private _placeSelectionChangeSubscription: Subscription;
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(
      private _storeMapService: StoreMapService,
      private _monitoringService: MonitoringService,
      private _changeDetectorRef: ChangeDetectorRef) { }
   //================================================================================   
   public initialize(): Promise<boolean>
   {
      return new Promise(async (resolve) =>
      {
         this._initializationPromiseResolver = resolve;
         setTimeout(() => this._completeInitializationIfNotDone(false), 60000); // wait up-to 1 minute
         //
         try 
         {
            // Resolve store-number
            this._deviceStoreNumber = await this._storeMapService.getDeviceStoreNumber();
            this._deviceStoreStartAddress = await this._storeMapService.getDeviceStoreStartAddress();

            // Setup communication with extension
            window.addEventListener('message', this._handleMessageFromExtension);

            // Load frame 
            this._changeDetectorRef.detectChanges();
            this._htmlFrame.nativeElement.src = this._storeMapService.getMapViewerSiteUrl();

            // Subscribe to events
            this._placeSelectionChangeSubscription = this._storeMapService.placeSelectionChangeStream.subscribe(
               this._handlePlaceSelectionChangeEvent.bind(this));
         }
         catch (error) 
         {
            console.log(error);
            this._completeInitializationIfNotDone(false);
         }
      });
   }
   //================================================================================
   ngOnDestroy()
   {
      window.removeEventListener('message', this._handleMessageFromExtension);
      //
      if (this._placeSelectionChangeSubscription)
      {
         this._placeSelectionChangeSubscription.unsubscribe();
         this._placeSelectionChangeSubscription = null;
      }
   }
   //================================================================================
   //#endregion

   //#region - Event handlers
   //================================================================================
   private _handlePlaceSelectionChangeEvent(place: StorePlace | null)
   {
      if (this._initializationPromiseResolver)
         return;
      //             
      const startAddress = this._deviceStoreStartAddress;
      let eventProperties: { placeType: string, placeName: string, placeLocation: string; } = null;
      //
      if (StorePlaceUtil.isPopularLandmark(place) || StorePlaceUtil.isProductLocation(place))
      {
         this._renderStoreMap({
            mode: MapMode.location,
            selectedStorey: 1, // makes map zoom-in on render
            location: [{ address: place.address, showPin: true }],
            focusAddress: place.address,
            startAddress,
            showDirections: true
         });
         //
         if (StorePlaceUtil.isProductLocation(place))
         {
            (eventProperties as any) = { 
               placeType: 'ProductAisle', 
               placeName: place.name, 
               location: place.address,
               productId: place.product.id,
               productName: place.product.name
             };
         }
         else
            eventProperties = { placeType: 'Landmark', placeName: place.name, placeLocation: place.address };
      }
      else if (StorePlaceUtil.isService(place))
      {
         this._renderStoreMap({
            mode: MapMode.location,
            selectedStorey: place.storeyNumber,
            location: [{ address: place.location, showPin: true }],
            focusAddress: place.location,
            startAddress,
            showDirections: true
         });
         //
         eventProperties = { placeType: 'Service', placeName: place.name, placeLocation: place.location };
      }
      else if (StorePlaceUtil.isDepartment(place) && place.aisleLocations?.length)
      {
         const deparmentLocation = place.aisleLocations[0];
         //
         const locationItems: LocationItem[] = deparmentLocation.aisleLocation.map(location => ({ address: location }));
         if (deparmentLocation.labelLocation)
            locationItems.push({ address: deparmentLocation.labelLocation });
         //
         this._renderStoreMap({
            mode: MapMode.store,
            selectedStorey: deparmentLocation.storeyNumber,
            location: locationItems
         });
         //
         eventProperties = { placeType: 'Department', placeName: place.name, placeLocation: locationItems[0].address };
      }
      else
      {
         this._renderStoreMap({ mode: MapMode.store });
      }
      //
      if (eventProperties)
      {
         const storeData = this._storeMapService.storeData;
         (eventProperties as any) = {...eventProperties, storeNumber: storeData.storeNumber, storeName: storeData.storeName };
         //
         this._monitoringService.logEvent('StoreMapSearched', eventProperties);
      }
   }
   //================================================================================
   //#endregion

   //#region - Helpers
   //================================================================================
   private _completeInitializationIfNotDone(result: boolean)
   {
      const resolver = this._initializationPromiseResolver; this._initializationPromiseResolver = null;
      if (resolver)
      {
         resolver(result);
         //
         if (result && this._storeMapService.selectedPlace)
            setTimeout(() => this._handlePlaceSelectionChangeEvent(this._storeMapService.selectedPlace), 200);
      }
   }
   //================================================================================
   private _handleMessageFromExtension = (event: MessageEvent) =>
   {
      const message = event.data;
      if (message?.id !== 'TutchCoreExtension')
         return;
      //      
      if (message.code != 'USER_INTERACTION')
         console.log(COMPONENT_NAME, message);
      //
      switch (message.code)
      {
         case 'EXTENSION_LOADED_IN_FRAME':
            this._injectAgentScript();
            break;
         case 'AGENT_INJECTED':
            this._renderStoreMap({ mode: MapMode.store });
            break;
         case 'STOREMAP_EVENT':
            this._processStoreMapEvent(JSON.parse(message.data));
            break;
      }
   };
   //================================================================================
   private _postMessageToExtension(code: string, data?: any) 
   {
      const frameWindow = this._htmlFrame.nativeElement.contentWindow;
      const message = { id: 'TutchCoreKiosk', code } as any; if (data) message.data = data;
      //
      console.log(COMPONENT_NAME, 'MESSAGE_POSTED', message);
      frameWindow.postMessage(message, '*');
   }
   //================================================================================
   private _injectAgentScript()
   {
      this._postMessageToExtension('INJECT_SCRIPT', 'bunnings');
   }
   //================================================================================
   private _renderStoreMap(props: StoreMapProps)
   {
      props.selectedStorey = props.selectedStorey ?? 1;
      //
      const data = { ...props } as any;
      data.storeNumber = this._deviceStoreNumber;
      //
      this._postMessageToExtension('RENDER_STOREMAP', data);
   }
   //================================================================================
   private _processStoreMapEvent(event: any)
   {
      switch (event.name)
      {
         case 'STOREMAP_STORE':
            this._storeMapService.storeData = event;
            this._deviceStoreStartAddress = StorePlaceUtil.validateConfiguredStartAddress(this._deviceStoreStartAddress, this._storeMapService.storeData);
            break;
         case 'STOREMAP_RENDERING':
         case 'STOREMAP_RENDERED':
            this._completeInitializationIfNotDone(true);
            break;
      }
   }
   //================================================================================
   //#endregion
}
