import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { Subscription, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Service, StorePlace, StorePlaceUtil } from "../../models/store-map";
import { StoreMapService } from "../../services/store-map.service";

export const ListTypeValues = ['SERVICES', 'DEPARTMENTS'] as const;
export type ListType = typeof ListTypeValues[number];

class ListGroup
{
   constructor(
      public letter: string,
      public items = new Array<ListItem>()) { }
}

class ListItem
{
   constructor(
      public readonly place: StorePlace,
      private _service: StoreMapService) { }

   public get name(): string 
   {
      return this.place.name;
   }

   public get label(): string 
   {
      if (StorePlaceUtil.isService(this.place))
      {
         const address = this.place.location;
         //
         if (StorePlaceUtil.isLandmarkAddress(address))
            return StorePlaceUtil.parseLandmarkLabel(address);
      }
      return null;
   }

   public get iconUrl(): string 
   {
      return this.place.icon;
   }

   public get isSelected(): boolean
   {
      return this.place === this._service.selectedPlace;
   }
}

class LetterItem
{
   constructor(
      public letter: string,
      public group: ListGroup | null,
      public isSelected = false) { }
}

@Component({
   selector: 'app-store-place-list',
   templateUrl: 'store-place-list.component.html',
   styleUrls: ['store-place-list.component.scss']
})
export class StorePlaceListComponent implements OnDestroy
{
   //#region - Fields/Properties
   //================================================================================
   @Input()
   public listType: ListType;
   //================================================================================
   public groups = new Array<ListGroup>();
   public letters = new Array<LetterItem>();
   //================================================================================
   @ViewChild('htmlGroupList')
   private _htmlGroupList!: ElementRef<HTMLElement>;
   //================================================================================
   public get groupListScrollSpacing(): number
   {
      if (!this._groupListScrollSpacing)
         this._calculateGroupListScrollSpacing();
      return this._groupListScrollSpacing;
   }
   private _groupListScrollSpacing = 0;
   //================================================================================
   private _groupListScrollSubscription: Subscription;
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(
      private _storeMapService: StoreMapService,
      private _changeDetectorRef: ChangeDetectorRef) { }
   //================================================================================
   public initialize()
   {
      this._composeItems();
      //
      this._changeDetectorRef.detectChanges();
      this._bindGroupListScrollEventHandler();
   }
   //================================================================================
   ngOnDestroy()
   {
      this._unbindGroupListScrollEventHandler();
   }
   //================================================================================
   //#endregion

   //#region - Methods
   //================================================================================
   public selectItem(item: ListItem)
   {
      if (!item.isSelected)
         this._storeMapService.selectedPlace = item.place;
   }
   //================================================================================
   public selectLetter(itemToSelect: LetterItem, ensureGroupVisible = true)
   {
      this.letters.forEach(item => item.isSelected = (item == itemToSelect));
      //
      if (ensureGroupVisible)
         this._scrollGroupIntoView(itemToSelect.letter);
   }
   //================================================================================
   //#endregion

   //#region - Helpers
   //================================================================================
   private _composeItems()
   {
      let items: ListItem[];
      //
      if (this.listType == 'SERVICES')
      {
         items = (this._storeMapService.storeData.services || []).map(
            service => new ListItem(service, this._storeMapService));
      }
      else if (this.listType == 'DEPARTMENTS')
      {
         items = (this._storeMapService.storeData.departments || []).map(
            department => new ListItem(department, this._storeMapService));
      }
      //
      const groupsByLetter = new Map<string, ListGroup>();
      //
      let lastGroup: ListGroup = null;
      items.sort((x, y) => x.name.localeCompare(y.name)).forEach(item => 
      {
         const letter = item.name[0].toUpperCase();
         if (lastGroup?.letter != letter)
         {
            lastGroup = new ListGroup(letter);
            this.groups.push(lastGroup);
            //
            groupsByLetter.set(letter, lastGroup);
         }
         //
         lastGroup.items.push(item);
      });
      //
      const codeForA = 'A'.charCodeAt(0);
      const codeForZ = 'Z'.charCodeAt(0);
      //
      for (let i = codeForA; i <= codeForZ; i++)
      {
         const letter = String.fromCharCode(i);
         this.letters.push(new LetterItem(letter, groupsByLetter.get(letter)));
      }
   }
   //================================================================================
   private _calculateGroupListScrollSpacing()
   {
      if (this._htmlGroupList?.nativeElement?.offsetHeight)
      {
         const groupElement = this._htmlGroupList.nativeElement.querySelector<HTMLElement>('.group:last-child');
         this._groupListScrollSpacing = groupElement ? this._htmlGroupList.nativeElement.offsetHeight - groupElement.offsetHeight : 0;
      }
   }
   //================================================================================
   private _unbindGroupListScrollEventHandler()
   {
      if (this._groupListScrollSubscription)
      {
         this._groupListScrollSubscription.unsubscribe();
         this._groupListScrollSubscription = null;
      }
   }
   //================================================================================
   private _bindGroupListScrollEventHandler()
   {
      this._unbindGroupListScrollEventHandler();
      //
      this._groupListScrollSubscription = fromEvent(this._htmlGroupList.nativeElement, 'scroll')
         .pipe(debounceTime(300))
         .subscribe(this._handleGroupListScroll.bind(this));
   }
   //================================================================================
   private _handleGroupListScroll()
   {
      const groupElements = Array.from(this._htmlGroupList.nativeElement.querySelectorAll<HTMLElement>('.group'));
      if (!groupElements.length)
         return;
      //
      const itemElementHeight = groupElements[0].querySelector<HTMLElement>('.item').offsetHeight;
      const groupElementAtTop = groupElements.find(
         element => (element.offsetTop + element.offsetHeight) > this._htmlGroupList.nativeElement.scrollTop + itemElementHeight / 2 + 10);
      //   
      if (groupElementAtTop)
      {
         const letter = groupElementAtTop.getAttribute('data-letter');
         this.selectLetter(this.letters.find(item => item.letter == letter), false);
      }
   }
   //================================================================================
   private _scrollGroupIntoView(letter: string)
   {
      const groupElement = this._htmlGroupList.nativeElement.querySelector<HTMLElement>(`.group[data-letter="${letter}"]`);
      if (groupElement)
      {
         this._unbindGroupListScrollEventHandler();
         try { this._htmlGroupList.nativeElement.scrollTo({ top: groupElement.offsetTop }); }
         finally { this._bindGroupListScrollEventHandler(); }
      }
   }
   //================================================================================
   //#endregion   
}
