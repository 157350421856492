import { ProductInfo } from "tutch-kiosk-core";
import { StoreLandmarkIcons } from "./landmark-icons";

// Bunnings definitions
export interface DepartmentAisleLocation
{
   storeyNumber: string | number;
   aisleLocation: string[];
   labelLocation: string;
}

export interface Department
{
   name?: string;
   type?: string;
   icon?: string;
   activeIcon?: string;
   aisleLocations?: DepartmentAisleLocation[];
}

export interface Service
{
   name?: string;
   icon?: string;
   location: string;
   storeyNumber: number;
}

export enum MapMode
{
   store = "store",
   location = "location",
   list = "list"
}

export interface LocationItem
{
   address: string;
   showPin?: boolean;
}

export interface ListItem
{
   address: string;
   itemNumber?: string;
   checked?: boolean;
}

export interface StoreMapProps
{
   mode: MapMode;
   selectedStorey?: number | string;
   location?: LocationItem[] | string;
   list?: ListItem[];
   startAddress?: string;
   focusAddress?: string;
   showDirections?: boolean;
}

export interface StoreData
{
   storeNumber: string;
   storeName: string;
   departments: Department[];
   services: Service[];
   entrances: string[];
   addresses: string[];
   landmarks: StoreLandmark[];   // custom
}

export interface StoreLandmark   // custom
{
   address: string;
   name: string;
   icon?: string;
   popularityIndex?: number;
}

export interface ProductLocation // custom
{
   product: ProductInfo;
   address: string;
   name?: string;
   icon?: string; // not used but needed for StorePlace 
}

export type StorePlace = Department | Service | StoreLandmark | ProductLocation;

export class StorePlaceUtil 
{
   public static readonly SpecialOrdersDesk: StoreLandmark = { name: 'Special Orders Desk', address: 'Special Orders Desk_1' };
   //
   public static isDepartment(place: StorePlace): place is Department { return (place as Department)?.aisleLocations !== undefined; }
   public static isService(place: StorePlace): place is Service { return (place as Service)?.location !== undefined; }
   public static isPopularLandmark(place: StorePlace): place is StoreLandmark { return (place as StoreLandmark)?.popularityIndex !== undefined; }
   public static isProductLocation(place: StorePlace): place is ProductLocation { return (place as ProductLocation)?.product !== undefined; }
   //
   public static isLandmarkAddress(address: string): boolean
   {
      return address.includes('_');
   }
   public static parseLandmarkLabel(address: string)
   {
      return address.substring(0, address.indexOf('_'));
   }
   //
   public static resolveLandmarks(storeData: StoreData)
   {
      // Resolve landmarks based on convention
      storeData.landmarks = storeData.addresses
         .filter(address => this.isLandmarkAddress(address))
         .map(address => ({ address, name: this.parseLandmarkLabel(address) }));
      
      // Define popular landmarks
      const popularLandmarkEntries = [
         { name: 'toilets', icon: StoreLandmarkIcons.Toilets },
         { name: 'cafe', icon: StoreLandmarkIcons.HardwareCafe },
         { name: 'information', icon: StoreLandmarkIcons.InformationDesk },
         { name: 'collect', icon: StoreLandmarkIcons.ClickAndCollect }
      ];
      //
      popularLandmarkEntries.forEach((entry, index) => 
      {
         const landmark = storeData.landmarks.find(landmark => landmark.name.toLowerCase().includes(entry.name));
         if (landmark)
         {
            landmark.icon = entry.icon;
            landmark.popularityIndex = index + 1;
         }
      });

      // Adjust 'Special Orders Desk' address (if required)
      const specialOrdersDesk = storeData.landmarks.filter(landmark => landmark.address.startsWith(StorePlaceUtil.SpecialOrdersDesk.name))[0];
      if (specialOrdersDesk && specialOrdersDesk.address != StorePlaceUtil.SpecialOrdersDesk.address)
         StorePlaceUtil.SpecialOrdersDesk.address = specialOrdersDesk.address;
   }
   //
   public static validateConfiguredStartAddress(startAddress: string, storeData: StoreData): string
   {
      if (!startAddress || !storeData.addresses.includes(startAddress))
      {
         const configuredStartAddressInfo = startAddress ? `; CONFIGURED=[${startAddress}]` : '';
         //
         if (startAddress)
         {
            const aisleLabelLocationToFind = startAddress;
            startAddress = null;
            //
            for (const department of storeData.departments ?? []) 
            {
               const deparmentAisleLocation = (department.aisleLocations ?? []).find(location => location.labelLocation == aisleLabelLocationToFind);
               if (deparmentAisleLocation)
               {
                  startAddress = (deparmentAisleLocation.aisleLocation ?? [])[0];
                  break;
               }
            }
         }
         //
         if (!startAddress)
            startAddress = StorePlaceUtil.SpecialOrdersDesk.address; // entrance:${storeData.entrances[0]}
         //
         console.log('StorePlaceUtil', `START_ADDRESS: ADJUSTED=[${startAddress}]${configuredStartAddressInfo}`)
      }
      return startAddress;
   }
}