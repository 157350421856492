import { createDefaultKioskCoreConfig } from './defaults';
//
export const environment = { production: true };
export function createKioskCoreConfig()
{
   let config = createDefaultKioskCoreConfig(
      environment.production, 
      '',
      'tutchazureb2c');
   //
   config.websiteUrl = 'https://bunnings-uat.tutch.cloud';
   config.media.baseUrl = 'https://aisleplus-retailers-prod.s3.ap-southeast-2.amazonaws.com';
   config.appInsights.instrumentationKey = '1abe55ca-d11d-409c-9f97-4cbf6c035429';
   config.oidc.clientId = '31930050-a4dc-49bc-b0f0-938a68f8a30b';
   config.interaction.deviceUpdatesCheckIntervalSecs = 60;
   config.interaction.dvmUpdatesCheckIntervalSecs = 60;
   config.interaction.catalogFullUpdatesCheckIntervalSecs = 900;   
   config.interaction.pageIdleDurationSecs = 120;
   config.interaction.cartIdleDurationSecs = 120; 
   //
   console.log('environment.uat', config);
   return config;
}