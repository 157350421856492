<div class="container">

   <div class="content">
      <div class="title">
         Congratulations!
      </div>   
      <div class="message">
         <div>Your order has been sent!</div>
         <div>Thank you for shopping with us!</div>
      </div>
   </div>

   <div class="action-bar">
      <button mat-button (click)="handleCommitAction()">OK</button>
   </div> 
 
 </div>