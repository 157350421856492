import { KioskCoreConfig } from 'tutch-kiosk-core';

export interface AppConfig extends KioskCoreConfig
{
   mapViewerUrl: string;
}

const IDA_TENANT: string = 'tutchb2c';
const KIOSK_TENANT: string = 'tutch.cloud';

export function createDefaultKioskCoreConfig(
   isProductionMode: boolean,
   environmentCode: string = '-dev',
   idaTenant: string = IDA_TENANT): AppConfig 
{
   const IDA_TENANT_MS: string = `${idaTenant}.onmicrosoft.com`;
   const IDA_TENANT_B2C_LOGIN_URL: string = `https://${idaTenant}.b2clogin.com`;
   const IDA_TENANT_API_URL: string = `https://${IDA_TENANT_MS}/api`;
   const IDA_B2C_POLICY_SIGNIN: string = 'B2C_1A_USER_SIGNIN';

   const API_BASE_URL: string = `https://api${environmentCode}.${KIOSK_TENANT}`;

   return {
      isProductionMode: isProductionMode,
      isTrainingMode: true,
      websiteUrl: 'http://localhost:4206',
      defaultCurrency: 'AUD',
      //
      appInsights: {
         instrumentationKey: 'b0593b1b-3573-44db-98f4-bbabfd84b836',
         useDbStorageBuffer: false
      },
      //
      oidc: {
         cfgServer: `${IDA_TENANT_B2C_LOGIN_URL}/${IDA_TENANT_MS}/v2.0/.well-known/openid-configuration?p=${IDA_B2C_POLICY_SIGNIN}`,
         stsServer: `${IDA_TENANT_B2C_LOGIN_URL}/${IDA_TENANT_MS}/oauth2/v2.0/authorize?p=${IDA_B2C_POLICY_SIGNIN}`,
         clientId: '264ae43d-f2f1-4bbd-a0dd-6a613e979915',
         scopes: `openid ${IDA_TENANT_API_URL}/read ${IDA_TENANT_API_URL}/write ${IDA_TENANT_API_URL}/user_impersonation`
      },
      //
      dotnet: {
         tokenUrl: `${API_BASE_URL}/token`,
         authUrl: `${API_BASE_URL}/auth`,
         registryUrl: `${API_BASE_URL}/registry`,
         workflowUrl: `${API_BASE_URL}/workflow`,
         catalogUrl: `${API_BASE_URL}/catalog`,
         orderUrl: `${API_BASE_URL}/order`,
         paymentUrl: `${API_BASE_URL}/payment`,
         communicationUrl: `${API_BASE_URL}/communication`, 
         //cognitiveUrl: `${API_BASE_URL}/cognitive`,
      },
      //
      media: {
         baseUrl: 'https://aisleplus-retailers.s3.ap-southeast-2.amazonaws.com',
         defaultImageUrl: '/assets/icons/kiosk/default.svg',
         spinnerImageUrl: '/assets/icons/kiosk/spinner.gif',
         cacheMaxAgeSecs: 3600
      },
      //
      eftpos: {
         serverAddress: 'http://localhost:2011'
      },
      //
      interaction: {
         deviceUpdatesCheckIntervalSecs: 10,
         dvmUpdatesCheckIntervalSecs: 20,
         catalogFullUpdatesCheckIntervalSecs: 60,
         catalogPriceUpdatesCheckIntervalSecs: 30,
         catalogStockInWarehouseUpdatesCheckIntervalSecs: 20,
         catalogStockInStoreUpdatesCheckIntervalSecs: 10,
         pageIdleDurationSecs: 20,
         cartIdleDurationSecs: 30,
         disableIdleStateSound: true,
         productSearchTextMinLength: 3,
         productListMaxCount: 500,
         processingQuoteMessage: 'Processing basket...',
         useImmediateCategoriesInProductSearch: true,
         userFeedbackSurveyUrl: 'https://survey.medallia.com.au/?lldxyr-1',
         userFeedbackTriggerTimeoutSecs: 120,
      },
      //
      catalog: {
         ignoreWarehouseStockBelowThreshold: true,
         hideOutOfStockProducts: false
      },
      //
      brochure: {
         updatesCheckIntervalSecs: 60,
      },
      //
      mapViewerUrl: 'https://map.bunnings.com.au/'      
   };
}
