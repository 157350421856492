import { Component, ChangeDetectorRef } from '@angular/core';
import { CatalogService, ProductListViewComponentBase, ProductSearchService, MonitoringService } from 'tutch-kiosk-core';

const SLIDES_AREA_COL_COUNT = 6;
const SLIDES_AREA_ROW_COUNT = 2;

@Component({
   selector: 'app-product-list-view',
   templateUrl: 'product-list-view.component.html',
   styleUrls: ['product-list-view.component.scss'],
   providers: [{ provide: ProductListViewComponentBase, useExisting: ProductListViewComponent }]
})
export class ProductListViewComponent extends ProductListViewComponentBase
{
   constructor(
      catalogService: CatalogService,
      productSearchService: ProductSearchService,
      monitoringService: MonitoringService,
      changeDetector: ChangeDetectorRef) 
   {
      super(catalogService, productSearchService, monitoringService, changeDetector);
      //
      this.isCategoriesTreatedAsAttribute = true;
      this.isProductBrandFilterable = true;
   }

   public isAutoSlidesPerViewCenteringEnabled(isFilterBarMenuVisible: boolean): boolean
   {
      let displayedProductCount = this.displayedProducts ? this.displayedProducts.length : 0;
      return displayedProductCount <= (SLIDES_AREA_COL_COUNT - (isFilterBarMenuVisible ? 2 : 0)) * SLIDES_AREA_ROW_COUNT;
   }   
}