import { Component } from '@angular/core';
import { CheckoutStepQuoteComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-checkout-step-quote',
   templateUrl: 'checkout-step-quote.component.html',
   styleUrls: ['checkout-step-quote.component.scss'],
   providers: [{ provide: CheckoutStepQuoteComponentBase, useExisting: CheckoutStepQuoteComponent }]
})
export class CheckoutStepQuoteComponent extends CheckoutStepQuoteComponentBase { }
