<kiosk-toolbar>
   <kiosk-cart-status-box></kiosk-cart-status-box>
   <kiosk-product-search-box></kiosk-product-search-box>
</kiosk-toolbar>

<div class="catalog-main-content" *ngIf="product">
   <kiosk-animated-panel>      
      <app-product-info-view *ngIf="!isMediaMode && !isVideoMode" [product]="product" 
         (mediaFullScreenRequested)="navigateToProductMedia($event)"
         (videoFullScreenRequested)="navigateToProductVideo()"
         (relatedProductActionRequested)="navigateToRelatedProduct($event)">
      </app-product-info-view>
      <kiosk-product-media-view *ngIf="isMediaMode" [product]="product" (viewDismissed)="navigateBackToMainView()"></kiosk-product-media-view>      
      <kiosk-product-video-view *ngIf="isVideoMode" [product]="product" (viewDismissed)="navigateBackToMainView()"></kiosk-product-video-view>      
   </kiosk-animated-panel>
</div>

<kiosk-user-idle-state-watcher [pageId]="pageId" [customUserInteractionChecker]="userInteractionChecker"></kiosk-user-idle-state-watcher>
