<kiosk-toolbar [customNavigateBackHandler]="navigateBackHandler">
   <kiosk-cart-status-box></kiosk-cart-status-box>
   <kiosk-product-search-box (keyboardPresented)="handleKeyboardPresentedEvent()"></kiosk-product-search-box>
</kiosk-toolbar>

<ng-container *ngIf="category">
   <div class="catalog-main-content products" *ngIf="products.length">
      <app-product-list-view></app-product-list-view>
   </div>   
   <div class="catalog-main-content categories" *ngIf="!products.length && categories.length">
      <app-category-list-view [categories]="categories"></app-category-list-view>
   </div>   
</ng-container>

<kiosk-user-idle-state-watcher [pageId]="pageId" [customUserInteractionChecker]="userInteractionChecker"></kiosk-user-idle-state-watcher>