<ng-container *ngIf="isMultipleCategoriesAvailable">
   <div class="category selected" *ngIf="selectedCategory">
      <mat-icon (click)="selectParentCategory()">arrow_back_ios</mat-icon>
      <span (click)="selectParentCategory()">{{selectedCategory.name}}</span>
   </div>
   <div class="child-categories">
      <div *ngFor="let childCategory of childCategories" class="category child">
         <mat-icon (click)="selectChildCategory(childCategory)">arrow_forward_ios</mat-icon>
         <span (click)="selectChildCategory(childCategory)">{{childCategory.name}}</span>
      </div>
   </div>      
</ng-container>

<ng-container *ngIf="!isMultipleCategoriesAvailable">
   <div class="category selected kiosk-non-interactive" *ngIf="selectedCategory">
      <mat-icon>remove</mat-icon>
      <span>{{selectedCategory.name}}</span>
   </div>
</ng-container>