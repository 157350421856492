import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { StoreMapService } from "../../services/store-map.service";
import { StorePlaceListComponent } from "../store-place-list/store-place-list.component";
import { Subscription } from "rxjs";
import { StorePlace, StorePlaceUtil } from "../../models/store-map";
import { ComponentSharedService, ProductInfo } from "tutch-kiosk-core";
import { StoreLandmarkListComponent } from "../store-landmark-list/store-landmark-list.component";

enum MENU_STATE
{
   INTRODUCTION,
   SERVICE_LIST,
   DEPARTMENT_LIST,
   PRODUCT_INFO
}

@Component({
   selector: 'app-store-search-menu',
   templateUrl: 'store-search-menu.component.html',
   styleUrls: ['store-search-menu.component.scss']
})
export class StoreSearchMenuComponent 
{
   //#region - Fields/Properties
   //================================================================================
   public get storeName(): string
   {
      return this._storeMapService.storeData?.storeName ?? '';
   }
   //================================================================================
   public get hasPopularLandmarks(): boolean
   {
      return this._storeMapService.storeData?.landmarks?.some(landmark => !!landmark.popularityIndex) ?? false;
   }
   //================================================================================
   public MENU_STATE = MENU_STATE;
   public menuState = MENU_STATE.INTRODUCTION;   
   //================================================================================
   @ViewChild(StoreLandmarkListComponent)
   private _storeLandmarkList: StoreLandmarkListComponent;
   @ViewChildren(StorePlaceListComponent)
   private _storePlaceLists = new QueryList<StorePlaceListComponent>();
   //================================================================================
   public selectedProduct: ProductInfo | null = null;
   private _placeSelectionChangeSubscription: Subscription;
   //================================================================================
   //#endregion

   //#region - Events
   //================================================================================
   @Output()
   public readonly productSearchRequest = new EventEmitter<void>();
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(
      private _services: ComponentSharedService,
      private _storeMapService: StoreMapService) { }
   //================================================================================
   public initialize()
   {
      // Initialize lists
      this._storeLandmarkList.initialize();
      this._storePlaceLists.forEach(list => list.initialize());

      // Subscribe to events
      this._placeSelectionChangeSubscription = this._storeMapService.placeSelectionChangeStream.subscribe(
         this._handlePlaceSelectionChangeEvent.bind(this));
   }
   //================================================================================
   ngOnDestroy()
   {
      if (this._placeSelectionChangeSubscription)
      {
         this._placeSelectionChangeSubscription.unsubscribe();
         this._placeSelectionChangeSubscription = null;
      }
   }
   //================================================================================
   //#endregion

   //#region - Methods
   //================================================================================
   public setMenuState(value: MENU_STATE)
   {
      if (this.menuState != value)
      {
         this._storeMapService.selectedPlace = null;
         this.menuState = value; // !!! after due to _handlePlaceSelectionChangeEvent
      }
   }
   //================================================================================
   //#endregion   

   //#region - Event handlers
   //================================================================================
   public handleProductSearchRequest()
   {
      const storeData = this._storeMapService.storeData;
      this._services.monitoringService.logEvent('StoreMapProductSearchInvoked', { storeNumber: storeData.storeNumber, storeName: storeData.storeName });
      //
      this.productSearchRequest.emit();
   }
   //================================================================================
   public handleProductTileAction()
   {
      this._services.userInteractionService.navigateToProduct(this.selectedProduct.id);
   }
   //================================================================================
   private _handlePlaceSelectionChangeEvent(place: StorePlace | null)
   {
      this.selectedProduct = null;
      this.menuState = MENU_STATE.INTRODUCTION;
      //
      if (place)
      {
         if (StorePlaceUtil.isProductLocation(place))
         {
            this.selectedProduct = place.product;
            this.menuState = MENU_STATE.PRODUCT_INFO;
         }
         else if (StorePlaceUtil.isService(place))
            this.menuState = MENU_STATE.SERVICE_LIST;
         else if (StorePlaceUtil.isDepartment(place))
            this.menuState = MENU_STATE.DEPARTMENT_LIST;
      }
   }
   //================================================================================
   //#endregion
}