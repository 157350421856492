<kiosk-toolbar [customNavigateBackHandler]="navigateBackHandler">
   <kiosk-cart-status-box></kiosk-cart-status-box>
   <kiosk-product-search-box></kiosk-product-search-box>
   <div [hidden]="!canEmailSelectedBrochure">
      <button class="email-button" mat-button (click)="emailSelectedBrochure()">
         <mat-icon>email</mat-icon>
      </button>
      <kiosk-call-to-action-panel>
         <button mat-button (click)="emailSelectedBrochure()">Email brochure</button>
      </kiosk-call-to-action-panel>
   </div>
</kiosk-toolbar>

<ng-container *ngIf="category">
   <div class="index-main-content brochures" *ngIf="brochures.length">
      <app-brochure-list-view (selectedBrochureViewInitialized)="handleSelectedBrochureViewInitializedEvent()"></app-brochure-list-view>
   </div>
   <div class="index-main-content categories" *ngIf="!brochures.length && categories.length">
      <app-brochure-category-list-view [categories]="categories"></app-brochure-category-list-view>
   </div>
</ng-container>

<kiosk-user-idle-state-watcher [pageId]="pageId"
   [customUserInteractionChecker]="userInteractionChecker"></kiosk-user-idle-state-watcher>