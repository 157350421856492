import { Component } from '@angular/core';
import { BrochureTileComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-brochure-tile',
   templateUrl: 'brochure-tile.component.html',
   styleUrls: ['brochure-tile.component.scss'],
   providers: [{ provide: BrochureTileComponentBase, useExisting: BrochureTileComponent }]
})
export class BrochureTileComponent extends BrochureTileComponentBase {}
