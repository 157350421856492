import { Component } from "@angular/core";
import { StoreLandmark } from "../../models/store-map";
import { StoreMapService } from "../../services/store-map.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

class ListItem
{
   constructor(
      public readonly landmark: StoreLandmark,
      private _service: StoreMapService,
      private _sanitizer: DomSanitizer) { }

   public get name(): string 
   {
      return this.landmark.name;
   }

   public get iconHtml(): SafeHtml
   {
      if (!this._iconHtml)
         this._iconHtml = this._sanitizer.bypassSecurityTrustHtml(this.landmark.icon);
      return this._iconHtml;
   }
   private _iconHtml: SafeHtml;

   public get isSelected(): boolean
   {
      return this.landmark === this._service.selectedPlace;
   }
}

@Component({
   selector: 'app-store-landmark-list',
   templateUrl: 'store-landmark-list.component.html',
   styleUrls: ['store-landmark-list.component.scss']
})
export class StoreLandmarkListComponent
{
   //#region - Fields/Properties
   //================================================================================
   public items: ListItem[];
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(
      private _storeMapService: StoreMapService,
      private _sanitizer: DomSanitizer) { }
   //================================================================================
   public initialize()
   {
      this.items = this._storeMapService.storeData.landmarks
         .filter(landmark => !!landmark.popularityIndex)
         .sort((x, y) => x.popularityIndex - y.popularityIndex)
         .map(landmark => new ListItem(landmark, this._storeMapService, this._sanitizer));
   }
   //================================================================================
   //#endregion

   //#region - Methods
   //================================================================================
   public toggleItemSelection(item: ListItem)
   {
      this._storeMapService.selectedPlace = !item.isSelected ? item.landmark : null;
   }
   //================================================================================
   //#endregion
}
