<button mat-button [mat-menu-trigger-for]="OptionsMenu">
   <mat-icon svgIcon="sort"></mat-icon>
   <span>{{getOptionText(selectedOption)}}</span>
</button>

<mat-menu #OptionsMenu class="app-product-sort-selector">
   <mat-radio-group [(ngModel)]="selectedOption" (ngModelChange)="handleSelectionChangeEvent()" >
      <div *ngFor="let option of options" mat-menu-item>
         <mat-radio-button [value]="option" disableRipple="true">
            {{getOptionText(option)}}
         </mat-radio-button>
      </div>
   </mat-radio-group>
</mat-menu>
