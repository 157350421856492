<kiosk-toolbar></kiosk-toolbar>

<div class="wizard-steps">
   <app-checkout-step-quote *ngIf="isCurrentStep(WIZARD_STEP.QUOTE)"
      (quoteCompleted)="handleQuoteCompletedEvent()">
   </app-checkout-step-quote>
   <app-checkout-step-order *ngIf="isCurrentStep(WIZARD_STEP.ORDER)" 
      (orderViewed)="handleOrderViewedEvent()">
   </app-checkout-step-order>
</div>

<kiosk-user-idle-state-watcher [pageId]="pageId" [isCartPage]="true"
   [customUserInteractionChecker]="userInteractionChecker" [customIdleStateHandler]="idleStateHandler">
</kiosk-user-idle-state-watcher>