<div class="container" [hidden]="isMenuVisible">

   <!-- filter-menu-toggler -->
   <button mat-button (click)="toggleMenu()" class="menu-toggler">
      <mat-icon svgIcon="filters"></mat-icon>
      <span>All Filters</span>
   </button>

   <!-- filter-list-->
   <ng-container *ngIf="!filterList.isEmpty">
      <button mat-button *ngFor="let filter of filterList.filters" (click)="removeFilter(filter)" class="filter">
         <mat-icon>close</mat-icon>
         <span>{{filter.text}}</span>
      </button>
      <button mat-button *ngIf="filterList.filters.length > 1" (click)="clearFilters()" class="clear">
         <mat-icon>refresh</mat-icon>
         <span>Reset</span>
      </button>
   </ng-container>

</div>

<app-product-filter-menu [hidden]="!isMenuVisible"
   (valueChanged)="valueChanged.emit()"
   (valueCleared)="clearFilters()"
   (dismissRequested)="hideMenu()">
</app-product-filter-menu>