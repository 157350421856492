import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { TutchKioskCoreModule, KIOSK_FOOTER_BAR_CONTENT_TYPE_TOKEN } from 'tutch-kiosk-core';
import { HomePage } from './pages/home/home.page';
import { FooterBarContentComponent } from './components/footer-bar-content/footer-bar-content.component';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      MatButtonModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDialogModule,
      MatIconModule,
      MatMenuModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      TutchKioskCoreModule
   ],
   declarations: [
      HomePage,
      FooterBarContentComponent
   ],
   exports: [
      MatButtonModule,
      MatCheckboxModule,
      MatChipsModule,
      MatIconModule,
      MatMenuModule,
      MatRadioModule
   ],
   providers: [
      { provide: KIOSK_FOOTER_BAR_CONTENT_TYPE_TOKEN, useValue: FooterBarContentComponent }
   ]
})
export class KioskModule { }
