import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { NouisliderModule } from 'ng2-nouislider';
import { TutchKioskCoreModule } from 'tutch-kiosk-core';
import { KioskModule } from '../kiosk/kiosk.module';
import { CartModule } from '../cart/cart.module';
import { CategoryListViewComponent } from './components/category-list-view/category-list-view.component';
import { CategorySelectorComponent } from './components/category-selector/category-selector.component';
import { CategoryTileComponent } from './components/category-tile/category-tile.component';
import { CatalogPage } from './pages/catalog/catalog.page';
import { CategoryPage } from './pages/category/category.page';
import { CategoryListPage } from './pages/category-list/category-list.page';
import { ProductTileComponent } from './components/product-tile/product-tile.component';
import { ProductPage } from './pages/product/product.page';
import { ProductInfoViewComponent } from './components/product-info-view/product-info-view.component';
import { ProductPriceComponent } from './components/product-price/product-price.component';
import { ProductListPage } from './pages/product-list/product-list.page';
import { ProductSortSelectorComponent } from './components/product-sort-selector/product-sort-selector.component';
import { ProductFilterBarComponent } from './components/product-filter-bar/product-filter-bar.component';
import { ProductFilterMenuComponent } from './components/product-filter-menu/product-filter-menu.component';
import { ProductListViewComponent } from './components/product-list-view/product-list-view.component';
import { PriceRangeSelectorComponent } from './components/price-range-selector/price-range-selector.component';
import { ProductAttributeOptionListComponent } from './components/product-attribute-option-list/product-attribute-option-list.component';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      MatTabsModule,
      NouisliderModule,
      TutchKioskCoreModule,
      KioskModule,
      CartModule
   ],
   declarations: [
      CatalogPage,
      CategoryPage,
      CategoryListPage,
      CategoryListViewComponent,
      CategoryTileComponent,
      CategorySelectorComponent,
      ProductPage,
      ProductListPage,
      ProductListViewComponent,
      ProductTileComponent,
      ProductInfoViewComponent,
      ProductPriceComponent,
      ProductSortSelectorComponent,
      ProductFilterBarComponent,
      ProductFilterMenuComponent,
      PriceRangeSelectorComponent,
      ProductAttributeOptionListComponent
   ],
   exports: [
      CategorySelectorComponent,
      ProductSortSelectorComponent,
      ProductFilterBarComponent,
      ProductFilterMenuComponent,
      ProductListViewComponent,
      ProductTileComponent
   ]
})
export class CatalogModule { } 