import { Component } from '@angular/core';
import { ProductLocation, StorePlaceUtil } from 'src/app/map/models/store-map';
import { StoreMapService } from 'src/app/map/services/store-map.service';
import { CartService, CatalogService, DeviceService, MediaService, ProductInfoViewComponentBase, UserInteractionService } from 'tutch-kiosk-core';

enum BADGE_CODE
{
   MARKETPLACE = 'marketplace',
   SPECIAL_ORDER = 'special-order',
   INSTORE = 'instore'
}

@Component({
   selector: 'app-product-info-view',
   templateUrl: 'product-info-view.component.html',
   styleUrls: ['product-info-view.component.scss'],
   providers: [{ provide: ProductInfoViewComponentBase, useExisting: ProductInfoViewComponent }]
})
export class ProductInfoViewComponent extends ProductInfoViewComponentBase
{
   //#region - Properties
   //================================================================================
   public isTabAnimationCompleted = false;
   //================================================================================
   public get inStoreLocations(): ProductLocation[] | null
   {
      return this.deviceService.isOnline ? this._inStoreLocations : null;
   }
   private _inStoreLocations: ProductLocation[] | null = null;
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(
      catalogService: CatalogService,
      cartService: CartService,
      deviceService: DeviceService,
      mediaService: MediaService,
      userInteractionService: UserInteractionService,
      private _storeMapService: StoreMapService)
   {
      super(catalogService, cartService, deviceService, mediaService, userInteractionService);
   }
   //================================================================================
   override async ngOnInit()
   {
      await super.ngOnInit();
      //
      if (this.deviceService.isOnline && this._storeMapService.getMapViewerSiteUrl() && await this._storeMapService.getDeviceStoreNumber())
      {
         switch (this.getBadgeCode())
         {
            case BADGE_CODE.INSTORE: 
               this._inStoreLocations = (await this._storeMapService.getProductInStoreLocations(this.product))?.splice(0, 2);
               break;
            case BADGE_CODE.SPECIAL_ORDER:                
               this._inStoreLocations = [{...StorePlaceUtil.SpecialOrdersDesk, product: this.product }];
               break;
         }
      }
   }
   //================================================================================
   //#endregion

   //#region - General
   //================================================================================
   public getNameFontSize()
   {
      let charCount = this.product.name.length;
      if (charCount > 100)
         return 30;
      else if (charCount > 50)
         return 35;
      else
         return 40;
   }
   //================================================================================
   public getStockHtml(): string
   {
      let lowStockThreshold = this.catalogSettings?.lowStockThreshold || 0;
      //
      let quantityInStore = this.product.quantity;
      let quantityAvailableToOrder = this.product.quantityAvailableToOrder;
      //
      let html = '';
      if (quantityInStore == 0)
         html = 'Out of stock in Store';
      else if (lowStockThreshold && quantityInStore > lowStockThreshold)
         html = `More than ${lowStockThreshold} available in Store`;
      else
         html = `${quantityInStore} Available in Store`;
      //   
      html += '<br>';
      //
      if (quantityAvailableToOrder > quantityInStore)
         html += 'Available in Warehouse';
      else
         html += 'Not Available in Warehouse';
      //
      return html;
   }
   //================================================================================
   //#endregion

   //#region - Badge
   //================================================================================
   public getBadgeCode(): BADGE_CODE
   {
      let status = this.product.getStatusAsText();
      if (status)
      {
         status = status.toUpperCase();
         //
         if (status == 'MARKETPLACE')
            return BADGE_CODE.MARKETPLACE;
         else if (status.includes('CSO'))
            return BADGE_CODE.SPECIAL_ORDER;
         else if (status.includes('INSTORE'))
            return BADGE_CODE.INSTORE;
      }
      return null;
   }
   //================================================================================
   public getBadgeText(): string
   {
      switch (this.getBadgeCode())
      {
         case BADGE_CODE.MARKETPLACE: return 'Marketplace';
         case BADGE_CODE.SPECIAL_ORDER: return 'Special Order';
         default: return '';
      }
   }
   //================================================================================
   public getBadgeInfo(): string
   {
      switch (this.getBadgeCode())
      {
         case BADGE_CODE.MARKETPLACE: return 'Available online only';
         case BADGE_CODE.SPECIAL_ORDER: return 'This item is not stocked in store, order online';
         default: return '';
      }
   }
   //================================================================================
   public getBadgeDeliveryHtml(): string
   {
      if (this.getBadgeCode() == BADGE_CODE.MARKETPLACE && !!this.product.brand)
         return `Items sold and delivered by <span class='${BADGE_CODE.MARKETPLACE}'>${this.product.brand}</span>`;
      else
         return '';
   }
   //================================================================================
   //#endregion

   //#region - Cart  
   //================================================================================
   public canAddToCart()
   {
      return this.isDeviceOnline && this.getBadgeCode() != BADGE_CODE.INSTORE;
   }
   //================================================================================
   //#endregion

   //#region - Map
   //================================================================================
   public showMap(location: ProductLocation)
   {
      this._storeMapService.selectedPlace = location;
      this.userInteractionService.navigateToMap();
   }
   //================================================================================
   //#endregion
}