<kiosk-toolbar>
   <kiosk-cart-status-box></kiosk-cart-status-box>   
   <kiosk-product-search-box #productSearchBox></kiosk-product-search-box>
</kiosk-toolbar>

<kiosk-animated-panel>
   <div class="main-content" [class.initialized]="isInitialized">
      <app-map-viewer></app-map-viewer>
      <app-store-search-menu (productSearchRequest)="productSearchBox.setFocusToInput(true)"></app-store-search-menu>
   </div>   
</kiosk-animated-panel>
