import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { NouisliderModule } from 'ng2-nouislider';
import { TutchKioskCoreModule } from 'tutch-kiosk-core';
import { KioskModule } from '../kiosk/kiosk.module';
import { CartModule } from '../cart/cart.module';
import { BrochureCategoryListViewComponent } from './components/category-list-view/category-list-view.component';
import { BrochureCategoryTileComponent } from './components/category-tile/category-tile.component';
import { BrochureCategoryPage } from './pages/category/category.page';
import { BrochureCategoryListPage } from './pages/category-list/category-list.page';
import { BrochureTileComponent } from './components/brochure-tile/brochure-tile.component';
import { BrochureListViewComponent } from './components/brochure-list-view/brochure-list-view.component';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      MatTabsModule,
      NouisliderModule,
      TutchKioskCoreModule,
      KioskModule,
      CartModule
   ],
   declarations: [
      BrochureCategoryPage,
      BrochureCategoryListPage,
      BrochureCategoryListViewComponent,
      BrochureCategoryTileComponent,
      BrochureListViewComponent,
      BrochureTileComponent,
   ]
})
export class BrochureModule { } 