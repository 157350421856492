<div class="container">
   <app-quote-item-list 
      (itemQuantityChanged)="handleItemQuantityChange($event)" 
      (itemRemovalRequested)="handleItemRemovalRequest($event)">
   </app-quote-item-list>
   <app-quote-summary 
      (quoteCompleted)="handleQuoteCompletion($event)"
      (quoteCleared)="handleItemsRemovalRequest()">
   </app-quote-summary>
   <kiosk-barcode-input-watcher [handlePasteEvent]="true"
      (barcodeScanned)="handleBarcodeInput($event)">
   </kiosk-barcode-input-watcher>
</div>