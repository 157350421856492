import { Component } from '@angular/core';
import { CatalogService, UserInteractionService, ProductListPageBase, MonitoringService } from 'tutch-kiosk-core';

@Component({
   selector: 'app-product-list-page',
   templateUrl: 'product-list.page.html',
   styleUrls: ['product-list.page.scss']
})
export class ProductListPage extends ProductListPageBase
{
   constructor(
      userInteractionService: UserInteractionService,
      monitoringService: MonitoringService,
      catalogService: CatalogService) 
   {
      super(userInteractionService, monitoringService, catalogService);
   }
}