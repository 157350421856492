import { Component, ChangeDetectorRef } from '@angular/core';
import { CatalogService, CategorySelectorComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-category-selector',
   templateUrl: 'category-selector.component.html',
   styleUrls: ['category-selector.component.scss'],
   providers: [{ provide: CategorySelectorComponentBase, useExisting: CategorySelectorComponent }]
})
export class CategorySelectorComponent extends CategorySelectorComponentBase 
{
   constructor(
      catalogService: CatalogService,
      changeDetector: ChangeDetectorRef) 
   {
      super(catalogService, changeDetector);
   }
}