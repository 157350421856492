<div class="container">

   <div class="toolbar">
      <mat-icon class="filters-icon" svgIcon="filters"></mat-icon>
      <span class="title">Filters</span>
      <button mat-button class="clear" *ngIf="!filterList.isEmpty" (click)="valueCleared.emit()">
         <mat-icon>refresh</mat-icon>
         <span>Reset</span>
      </button>
      <button mat-button class="close" (click)="dismissRequested.emit()">
         <mat-icon>close</mat-icon>
      </button>
   </div>

   <div class="filters">
      <div class="price-filter">
         <label>Price</label>
         <app-price-range-selector (valueChanged)="handleSelectedPriceRangeChangeEvent()"></app-price-range-selector>
      </div>

      <div class="offers-filter" [hidden]="!hasAvailableProductOffers">
         <label>Special Offers</label>
         <div class="input-field">
            <mat-checkbox [(ngModel)]="isOnSaleFilterChecked" (change)="handleOnSaleFilterChangeEvent()">
               On Sale
            </mat-checkbox>
         </div>
      </div>

      <ng-container *ngFor="let attribute of availableProductAttributes">
         <div *ngIf="attribute.options.length > 1" class="attribute-filter">
            <app-product-attribute-option-list [attribute]="attribute" (selectionChanged)="handleAttributeSelectorChangeEvent(attribute)">
            </app-product-attribute-option-list>
         </div>
      </ng-container>

   </div>
</div>