<div class="main-content">
   <div class="group-list" #htmlGroupList [style.padding-bottom.px]="groupListScrollSpacing" >
      <div class="group" *ngFor="let group of groups" [attr.data-letter]="group.letter">
         <label>{{group.letter}}</label>
         <div class="item-list">
            <div class="item" *ngFor="let item of group.items" [class.is-selected]="item.isSelected" (click)="selectItem(item)">
               <img *ngIf="item.iconUrl" src="{{item.iconUrl}}">
               <div>
                  <label class="name">{{item.name}}</label>
                  <label class="label" *ngIf="item.label">{{item.label}}</label>
               </div>
               <mat-icon>place</mat-icon>
            </div>
         </div>
      </div>
   </div>
   <div class="letter-list">
      <div *ngFor="let letter of letters">
         <div class="letter" [class.has-group]="!!letter.group" [class.is-selected]="letter.isSelected" (click)="selectLetter(letter)">
            <label>{{letter.letter}}</label>
         </div>
      </div>
   </div>
</div>