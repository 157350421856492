import { Component } from '@angular/core';
import { QuoteItemListComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-quote-item-list',
   templateUrl: 'quote-item-list.component.html',
   styleUrls: ['quote-item-list.component.scss'],
   providers: [{ provide: QuoteItemListComponentBase, useExisting: QuoteItemListComponent }]
})
export class QuoteItemListComponent extends QuoteItemListComponentBase
{
   public getNameFontSize(name: string)
   {
      let charCount = name.length;
      if (charCount > 100)
         return 19;
      else if (charCount > 50)
         return 23;
      else
         return 27;
   }
}