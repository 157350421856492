import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TutchKioskCoreModule } from 'tutch-kiosk-core';
import { CatalogModule } from '../catalog/catalog.module';
import { KioskModule } from '../kiosk/kiosk.module';
import { MapViewerComponent } from './components/map-viewer/map-viewer.component';
import { StoreLandmarkListComponent } from './components/store-landmark-list/store-landmark-list.component';
import { StorePlaceListComponent } from './components/store-place-list/store-place-list.component';
import { StoreSearchMenuComponent } from './components/store-search-menu/store-search-menu.component';
import { StoreMapPage, StoreMapPageHost } from './pages/store-map/store-map.page';
import { StoreMapService } from './services/store-map.service';

@NgModule({
   imports: [
      CommonModule,
      TutchKioskCoreModule,
      KioskModule,
      CatalogModule
   ],
   declarations: [
      MapViewerComponent,
      StoreLandmarkListComponent,
      StorePlaceListComponent,
      StoreSearchMenuComponent,
      StoreMapPage,
      StoreMapPageHost
   ],
   providers: [
      StoreMapService
   ]
})
export class MapModule { } 