import { Component } from '@angular/core';
import { BrochureCategoryTileComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-brochure-category-tile',
   templateUrl: 'category-tile.component.html',
   styleUrls: ['category-tile.component.scss'],
   providers: [{ provide: BrochureCategoryTileComponentBase, useExisting: BrochureCategoryTileComponent }]
})
export class BrochureCategoryTileComponent extends BrochureCategoryTileComponentBase { }
