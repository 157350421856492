<div class="product-tools" 
   [hidden]="!products || products.length < 2 || selectedProduct || isProductBeingSelected">
   <app-product-filter-bar #filterBar (valueChanged)="applyProductFilters()"></app-product-filter-bar>
   <span class="summary">{{displayedProductsSummary}}</span>
   <app-product-sort-selector (valueChanged)="applyProductSorting()"></app-product-sort-selector>
</div>

<kiosk-animated-panel>
   <div class="main-panel" [hidden]="selectedProduct || isProductBeingSelected"> 
      <kiosk-slides-carousel class="products"
         [slidesPerView]="'auto'" 
         [slidesPerColumn]="2" 
         [isAutoSlidesPerViewCenteringEnabled]="isAutoSlidesPerViewCenteringEnabled(filterBar?.isMenuVisible)"
         [items]="displayedProducts"
         [itemTemplate]="productTemplate"
         [itemsPerView]="12"
         [class.menu-offset]="filterBar.isMenuVisible">
      </kiosk-slides-carousel>       
   </div>
   <div *ngIf="selectedProduct">
      <app-product-info-view [hidden]="isProductMediaViewUsed || isProductVideoViewUsed" 
         [product]="selectedProduct" 
         (mediaFullScreenRequested)="showSelectedProductMedia($event)"
         (videoFullScreenRequested)="showSelectedProductVideo()"
         (relatedProductActionRequested)="selectProduct($event)">
      </app-product-info-view>
      <kiosk-product-media-view *ngIf="isProductMediaViewUsed" [product]="selectedProduct" (viewDismissed)="handleNavigateBack()"></kiosk-product-media-view>      
      <kiosk-product-video-view *ngIf="isProductVideoViewUsed" [product]="selectedProduct" (viewDismissed)="handleNavigateBack()"></kiosk-product-video-view>      
   </div>   
</kiosk-animated-panel>

<ng-template #productTemplate let-product="item">
   <app-product-tile [product]="product" (actionRequested)="selectProduct(product)"></app-product-tile>
</ng-template>