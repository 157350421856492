<div *ngIf="isUsedInProductTile" class="container-product-tile">
   <div class="price special" *ngIf="product.specialPrice">
      <span>{{product.specialPrice | currency}}</span>
      <span class="title">ON SALE NOW</span>
   </div>
   <div class="price normal" [class.has-special-price]="product.specialPrice">
      <span>{{product.price | currency}}</span>
   </div>
</div>

<div *ngIf="!isUsedInProductTile" class="container-product-info-view">
   <div class="price special" *ngIf="product.specialPrice">
      <div class="title">ON SALE NOW</div>
      <span class="value">{{product.specialPrice | currency}}</span>
   </div>
   <div class="price normal" [class.has-special-price]="product.specialPrice">
      <div class="title">&nbsp;</div>
      <span class="value">{{product.price | currency}}</span>
   </div>
</div>