<kiosk-animated-panel>
   <kiosk-slides-carousel *ngIf="categories" 
      [slidesPerView]="'auto'"
      [isAutoSlidesPerViewCenteringEnabled]="isAutoSlidesPerViewCenteringEnabled" 
      [isRowFlexDirection]="true"
      [slidesPerColumn]="slidesPerColumn"
      [items]="categories" 
      [itemsPerView]="itemsPerView"
      [itemTemplate]="CategoryTemplate"
      [style.scale]="slidesScalingFactor">
   </kiosk-slides-carousel>
</kiosk-animated-panel>

<ng-template #CategoryTemplate let-category="item">
   <app-brochure-category-tile [category]="category"></app-brochure-category-tile>
</ng-template>