<div class="main-content">

   <div class="intro-panel" [hidden]="menuState != MENU_STATE.INTRODUCTION">
      <div class="welcome">Welcome to Bunnings {{storeName}}</div>
      <div class="question">What are you looking for?</div>
      <div class="buttons">
         <button mat-button (click)="setMenuState(MENU_STATE.SERVICE_LIST)">Services</button>
         <button mat-button (click)="setMenuState(MENU_STATE.DEPARTMENT_LIST)">Departments</button>
         <button mat-button class="product-search-button" (click)="handleProductSearchRequest()">
            <mat-icon>search</mat-icon>
            <span>Find a product</span>
         </button>
      </div>
      <app-store-landmark-list [hidden]="!hasPopularLandmarks"></app-store-landmark-list>
   </div>

   <div class="list-panel" [hidden]="menuState == MENU_STATE.INTRODUCTION">
      <div class="title-bar">
         <label>
         {{
            menuState == MENU_STATE.SERVICE_LIST ? 'Services' : 
            menuState == MENU_STATE.DEPARTMENT_LIST ? 'Deparments' :
            menuState == MENU_STATE.PRODUCT_INFO ? 'Product' : ''
         }}
         </label>
         <button mat-button (click)="setMenuState(MENU_STATE.INTRODUCTION)">
            <mat-icon>close</mat-icon>
         </button>         
      </div>
      <app-store-place-list listType="SERVICES" [hidden]="menuState != MENU_STATE.SERVICE_LIST">
      </app-store-place-list>
      <app-store-place-list listType="DEPARTMENTS" [hidden]="menuState != MENU_STATE.DEPARTMENT_LIST">
      </app-store-place-list>
      <app-product-tile *ngIf="menuState == MENU_STATE.PRODUCT_INFO" [product]="selectedProduct" (actionRequested)="handleProductTileAction()">         
      </app-product-tile>
   </div>

</div>