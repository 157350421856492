import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreMapService } from 'src/app/map/services/store-map.service';
import { CallToActionPanelComponent, ComponentSharedService, MomentUtil } from 'tutch-kiosk-core';

const COMPONENT_NAME = 'FooterBarContentComponent'
const SESSION_DATA_KEY_USER_FEEDBACK_CALL_TO_ACTION_TRIGGERED = 'USER_FEEDBACK_CALL_TO_ACTION_TRIGGERED';

@Component({
   selector: 'app-footer-bar-content',
   templateUrl: 'footer-bar-content.component.html',
   styleUrls: ['footer-bar-content.component.scss']
})
export class FooterBarContentComponent implements OnInit, OnDestroy
{
   public isHelpContentAvailable = false;

   public get isStoreMapAvailable(): boolean 
   { 
      return this._services.deviceService.isOnline
         && !!this._storeMapService.getMapViewerSiteUrl()
         && !!this._deviceStoreNumber;
   }

   public get isUserFeedbackSurveyAvailable(): boolean 
   { 
      return this._services.deviceService.isOnline
         && !!this._services.userInteractionService.userFeedbackSurveyUrl;
   }   

   @ViewChild(CallToActionPanelComponent)
   private _callToActionPanel: CallToActionPanelComponent;

   private _deviceStoreNumber: string | null = null;
   private _userFeedbackCallToActionTimer: any = null;

   constructor(
      private _services: ComponentSharedService,
      private _storeMapService: StoreMapService) { }

   async ngOnInit()
   { 
      this.isHelpContentAvailable = await this._services.dvmService.isDvmResourceForHelpContentAvalable();
      this._deviceStoreNumber = await this._storeMapService.getDeviceStoreNumber();
      //
      if (this.isUserFeedbackSurveyAvailable)
         this._triggerUserFeedbackCallToAction();
   }

   ngOnDestroy()
   {
      if (this._userFeedbackCallToActionTimer)
         this._userFeedbackCallToActionTimer = clearTimeout(this._userFeedbackCallToActionTimer);
   }

   public showHelpContent()
   {
      this._services.userInteractionService.navigateToDvmForHelpContent();
   }  

   public showStoreMap()
   {
      this._services.userInteractionService.navigateToMap();
      this._services.monitoringService.logPageNavigation('store-map', `/kiosk/map`);
   }

   public showUserFeedbackSurvey()
   {
      const sessionInfo = this._services.userInteractionService.ensureInteractionSessionStarted();
      sessionInfo.data.set(SESSION_DATA_KEY_USER_FEEDBACK_CALL_TO_ACTION_TRIGGERED, true);
      //
      this._services.userInteractionService.navigateToExternalContent({ 
         contentUrl: this._services.userInteractionService.userFeedbackSurveyUrl});
   }
   
   private _triggerUserFeedbackCallToAction()
   {
      const triggerTimeout = this._services.userInteractionService.userFeedbackTriggerTimeout;
      if (!triggerTimeout)
         return;
      //
      const sessionInfo = this._services.userInteractionService.getInteractionSessionInfo();
      if (!sessionInfo)
         return;
      //
      const hasBeenTriggered = sessionInfo.data.get(SESSION_DATA_KEY_USER_FEEDBACK_CALL_TO_ACTION_TRIGGERED)
      if (hasBeenTriggered)
         return;
      //
      const sessionDurationInSecs = Math.round(MomentUtil.getDurationSince(sessionInfo.startedAt).asSeconds());
      const triggerCallToActionInSecs = Math.max(0, triggerTimeout.asSeconds() - sessionDurationInSecs) + 5;
      console.log(COMPONENT_NAME, `TRIGGER_USER_FEEDBACK_CALL_TO_ACTION: IN_SECS=${triggerCallToActionInSecs}; SESSION_DURATION_SECS=${sessionDurationInSecs}`);      
      //
      this._userFeedbackCallToActionTimer = setTimeout(() => this._playUserFeedbackCallToAction(), triggerCallToActionInSecs * 1000);
   }

   private _playUserFeedbackCallToAction()
   {
      const sessionInfo = this._services.userInteractionService.getInteractionSessionInfo();
      if (!sessionInfo)
      {
         console.log(COMPONENT_NAME, `PLAY_USER_FEEDBACK_CALL_TO_ACTION: NO_SESSION}`);      
         return;
      }
      //
      sessionInfo.data.set(SESSION_DATA_KEY_USER_FEEDBACK_CALL_TO_ACTION_TRIGGERED, true);
      console.log(COMPONENT_NAME, `PLAY_USER_FEEDBACK_CALL_TO_ACTION: STARTED}`);
      //
      this._callToActionPanel.playAnimation();
  }
}