import { Injectable } from '@angular/core';
import { DefaultProductSearchService, ProductRow, ProductSearchCriteria, ProductSearchEntry, TextUtil } from 'tutch-kiosk-core';
import THESAURUS_JSON from './product-search.thesaurus.json';

interface ThesaurusReplacementEntry
{
   from: string[];
   to: string;
   //
   inputTokens: string[];
   outputTokens: string[];
}

interface ThesaurusExpansionEntry
{
   from: string[];
   to: string[];
   //
   inputTokens: string[];
   outputTokens: string[];
}

interface ThesaurusAliasSetEntry extends Array<string>
{
   inputTokens: string[];
   outputTokens: string[];
}

interface Thesaurus
{
   replacements: ThesaurusReplacementEntry[];
   expansions: ThesaurusExpansionEntry[];
   aliasSets: ThesaurusAliasSetEntry[];
}

const SERVICE_NAME = 'ProductSearchService';

@Injectable()
export class ProductSearchService extends DefaultProductSearchService
{
   private _thesaurus: Thesaurus = null;
   private _productNameToTokensMap = new Map<string, string[]>();

   private _ensureThesaurus(): Thesaurus
   {
      // Check if thesaurus is already prepared
      if (this._thesaurus)
         return this._thesaurus;

      // Prepare thesaurus from JSON data
      console.time(`${SERVICE_NAME}-THESAURUS`);
      const thesaurus = THESAURUS_JSON as Thesaurus;
      const productSearchMinLength = this.userInteractionService.productSearchTextMinLength;
      //
      function deriveInputTokens(phrases: string[]): string[] // lower-case with spaces around
      {
         for (let j = 0; j < phrases.length; j++)
            phrases[j] = ` ${phrases[j].toLowerCase()} `;
         //
         return phrases;   
      }
      //
      const outputTokens = new Set<string>();
      function deriveOutputTokens(phrases: string[]): string[] // distinct words in lower-case
      {
         outputTokens.clear();
         //
         phrases
            .forEach(phrase => TextUtil.splitInWords(phrase).filter(word => word.length >= productSearchMinLength)
               .forEach(word => outputTokens.add(word.toLocaleLowerCase())));
         //
         return Array.from(outputTokens.values());
      }

      // Prepare replacements
      const replacements = (thesaurus.replacements = thesaurus.replacements ?? []);
      for (let i = replacements.length - 1; i >= 0; i--) 
      {
         const entry = replacements[i];
         //
         const from = entry.from ?? [];
         if (!from.length)
         {
            console.warn(SERVICE_NAME, 'REPLACEMENT_REMOVED', entry);
            replacements.splice(i, 1);
            continue;
         }
         //
         entry.inputTokens = deriveInputTokens(from);
         delete entry.from;         
         //
         entry.outputTokens = deriveOutputTokens([entry.to]);
         // console.log(SERVICE_NAME, 'REPLACEMENT_OUTPUT_TOKENS', entry.to, entry.outputTokens);
         delete entry.to;
      }

      // Prepare expansions
      const expansions = (thesaurus.expansions = thesaurus.expansions ?? []);
      for (let i = expansions.length - 1; i >= 0; i--) 
      {
         const entry = expansions[i];
         //
         const from = entry.from ?? [];
         if (!from.length)
         {
            console.warn(SERVICE_NAME, 'EXPANSION_REMOVED_FROM', entry);
            expansions.splice(i, 1);
            continue;
         }
         //
         entry.inputTokens = deriveInputTokens(from);
         delete entry.from;         
         //
         entry.outputTokens = deriveOutputTokens(entry.to);
         if (!entry.outputTokens.length)
         {
            console.warn(SERVICE_NAME, 'EXPANSION_REMOVED_TO', entry);
            expansions.splice(i, 1);
            continue;
         }
         // console.log(SERVICE_NAME, 'EXPANSION_OUTPUT_TOKENS', entry.to, entry.outputTokens);
         delete entry.to;
      }

      // Prepare alias-sets
      const aliasSets = (thesaurus.aliasSets = thesaurus.aliasSets ?? []);
      for (let i = aliasSets.length - 1; i >= 0; i--) 
      {
         const entry = aliasSets[i];
         //
         if (!entry?.length)
         {
            console.warn(SERVICE_NAME, 'ALIASSET_REMOVED_FROM', entry);
            aliasSets.splice(i, 1);
            continue;
         }
         //
         entry.inputTokens = [...deriveInputTokens(entry)];
         //
         entry.outputTokens = deriveOutputTokens(entry);
         if (!entry.outputTokens.length)
         {
            console.warn(SERVICE_NAME, 'ALIASSET_REMOVED_TO', entry);
            aliasSets.splice(i, 1);
            continue;
         }
         // console.log(SERVICE_NAME, 'ALIASSET_OUTPUT_TOKENS', entry, entry.outputTokens);
         entry.length = 0;
      }

      // Store and return prepared thesaurus
      console.timeEnd(`${SERVICE_NAME}-THESAURUS`); // console.log(SERVICE_NAME, 'THESAURUS', thesaurus);
      return this._thesaurus = thesaurus;
   }

   protected override adjustSearchCriteria(criteria: ProductSearchCriteria)
   {
      // Prepare
      console.time(`${SERVICE_NAME}-ADJUST_SEARCH_CRITERIA`);
      //
      const thesaurus = this._ensureThesaurus();
      const productSearchMinLength = this.userInteractionService.productSearchTextMinLength;
      const derivedSearchTokens = new Set<string>();

      // Normalize search text input => lower-case with spaces around
      let searchTextInput = ` ${criteria.searchTokens.map(token => token.toLowerCase()).join(' ')} `; 

      // Apply replacements first
      for (const entry of thesaurus.replacements) 
      {
         let searchTextAfterReplacement = searchTextInput;
         entry.inputTokens.forEach(token => searchTextAfterReplacement = searchTextAfterReplacement.replaceAll(token, ''));
         //
         if (searchTextInput != searchTextAfterReplacement)
         {
            searchTextInput = searchTextAfterReplacement;
            entry.outputTokens.forEach(token => derivedSearchTokens.add(token));
         }
      }
      //
      TextUtil.splitInWords(searchTextInput)
         .filter(word => word.length >= productSearchMinLength)
         .forEach(word => derivedSearchTokens.add(word));

      // Apply expansions
      for (const entry of thesaurus.expansions) 
      {
         if (entry.inputTokens.some(token => searchTextInput.includes(token)))
            entry.outputTokens.forEach(token => derivedSearchTokens.add(token));
      }

      // Apply alias-sets
      for (const entry of thesaurus.aliasSets) 
      {
         if (entry.inputTokens.some(token => searchTextInput.includes(token)))
            entry.outputTokens.forEach(token => derivedSearchTokens.add(token));
      }

      // Finalize list of search-tokens
      let searchTokens = Array.from(derivedSearchTokens.values());

      // Adjust input search-tokens (if required)
      let searchTextFromTokens = searchTokens.join(' ');
      if (searchTextInput.trim() != searchTextFromTokens)
      {
         console.log(SERVICE_NAME, `ADJUSTED: ${criteria.searchText} => ${searchTextFromTokens}`);
         criteria.searchTokens = searchTokens;
      }
      else
         console.log(SERVICE_NAME, `INPUTTED: ${criteria.searchText}`);
      //
      console.timeEnd(`${SERVICE_NAME}-ADJUST_SEARCH_CRITERIA`);   
   }
   
   protected override deriveProductNameMatchRank(product: ProductRow, searchTokens: string[]): number
   {
      // Parse product name-tokens
      const name = product.name.toLowerCase();
      //
      let nameTokens: string[] = this._productNameToTokensMap.get(name);
      if (!nameTokens)      
      {
         nameTokens = TextUtil.splitInWords(name).filter(word => word.length > this.userInteractionService.productSearchTextMinLength);
         this._productNameToTokensMap.set(name, nameTokens);
      }

      // Rank name-tokens by exact match first and then name by partial match
      let matchRank = 0; let isExactMatchRanked = false; 
      for (const searchToken of searchTokens) 
      {
         const searchTokenExactMatchCount = nameTokens.reduce((matchCount, nameToken) => matchCount + (nameToken == searchToken ? 1 : 0), 0);
         if (searchTokenExactMatchCount > 0)
         {
            matchRank += (10 + searchTokenExactMatchCount);
            isExactMatchRanked = true;
         }
         else if (name.includes(searchToken))   
            matchRank++;
      }

      // !!! Clear rank if no exact matches and not ALL search-tokens are found by partial match
      if (!isExactMatchRanked && matchRank < searchTokens.length)
         matchRank = 0;
      //
      return matchRank;
   }

   protected override sortSearchResultProductEntries(productEntries: ProductSearchEntry[])
   {
      productEntries.sort((x, y) => (y.matchRank - x.matchRank) || x.product.name.localeCompare(y.product.name));
      // TO_COMMENT_OUT: console.log(SERVICE_NAME, 'FOUND', productEntries.length, productEntries.slice(0, 20).map(entry => `${entry.matchRank} => ${entry.product.name}`)); 
   }
}