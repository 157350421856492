import { AfterContentInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AnimatedPanelComponent, ProductSearchBoxComponent, PromiseUtil, UserInteractionService } from 'tutch-kiosk-core';
import { MapViewerComponent } from '../../components/map-viewer/map-viewer.component';
import { StoreSearchMenuComponent } from '../../components/store-search-menu/store-search-menu.component';

const COMPONENT_NAME = 'StoreMapPage';

@Component({
   selector: 'app-store-map-page',
   templateUrl: 'store-map.page.html',
   styleUrls: ['store-map.page.scss']
})
export class StoreMapPage implements AfterContentInit
{
   //#region - Properties/Fields
   //================================================================================
   public isInitialized = false;
   //================================================================================
   @ViewChild(MapViewerComponent)
   private _mapViewer: MapViewerComponent;
   @ViewChild(StoreSearchMenuComponent)
   private _searchMenu: StoreSearchMenuComponent;
   @ViewChild(AnimatedPanelComponent)
   private _animatedPanel: AnimatedPanelComponent;
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(
      private _userInteractionService: UserInteractionService,
      private _changeDetectorRef: ChangeDetectorRef,
      private _elementRef: ElementRef<HTMLElement>) { }
   //================================================================================
   async ngAfterContentInit()
   {
      console.log(COMPONENT_NAME, 'ngAfterContentInit', this.isInitialized);
      //
      if (!this.isInitialized)
      {
         await this._userInteractionService.showBusyIndicator('Loading map...');
         try
         {
            this._changeDetectorRef.detectChanges();
            this.isInitialized = await this._mapViewer.initialize();
            //
            if (this.isInitialized)
            {
               this._searchMenu.initialize();
               this._animatedPanel.playEnterAnimation();
            }
            else
            {
               await this._userInteractionService.showProductNotFoundBox('Map is not available at the moment. Please try later...');
               this._userInteractionService.navigateBack();
            }
         }
         finally { await this._userInteractionService.hideBusyIndicator(); }
      }
      else
      {
         this._elementRef.nativeElement.style.display = '';
         await this._animatedPanel?.playEnterAnimation(true);
      }
   }
   //================================================================================
   public async handleNavigationLeaveEvent(): Promise<boolean>
   {
      await this._animatedPanel?.playLeaveAnimation(true);
      setTimeout(() => { this._elementRef.nativeElement.style.display = 'none'; });
      return true;
   }
   //================================================================================
   //#endregion
}

@Component({ 
   selector: 'app-store-map-page-host', 
   template: `<kiosk-user-idle-state-watcher pageId="${COMPONENT_NAME}Host"></kiosk-user-idle-state-watcher>` 
})
export class StoreMapPageHost implements AfterContentInit
{
   //#region - Fields !!! static
   //================================================================================
   public static viewContainerRef: ViewContainerRef;
   private static _hostedPageInstance: StoreMapPage;
   //================================================================================
   //#endregion

   //#region - Lifecycle
   //================================================================================
   constructor(private _componentFactoryResolver: ComponentFactoryResolver) { }
   //================================================================================
   ngAfterContentInit()
   {
      if (!StoreMapPageHost._hostedPageInstance)
      {
         const componentFactory = this._componentFactoryResolver.resolveComponentFactory(StoreMapPage);
         const componentRef = StoreMapPageHost.viewContainerRef.createComponent<StoreMapPage>(componentFactory);
         //
         StoreMapPageHost._hostedPageInstance = componentRef.instance;
      }
      else
         StoreMapPageHost._hostedPageInstance.ngAfterContentInit();      
   }
   //================================================================================
   public async handleNavigationLeaveEvent(): Promise<boolean>
   {
      await StoreMapPageHost._hostedPageInstance?.handleNavigationLeaveEvent();
      return true;
   }
   //================================================================================
   //#endregion
}