<button mat-button class="help-button" *ngIf="isHelpContentAvailable" (click)="showHelpContent()">
   <img>
</button>
<button mat-icon-button class="store-map-button" *ngIf="isStoreMapAvailable" (click)="showStoreMap()">
   <mat-icon>maps_home_work</mat-icon>
   <label>Map</label>
</button>
<div class="user-feedback-panel">
   <button mat-icon-button class="user-feedback-button" *ngIf="isUserFeedbackSurveyAvailable" (click)="showUserFeedbackSurvey()">
      <mat-icon>rate_review</mat-icon>
      <label>Feedback</label>
   </button>
   <kiosk-call-to-action-panel>
      <button mat-button (click)="showUserFeedbackSurvey()">Did we nail it?</button>
   </kiosk-call-to-action-panel>
</div>
