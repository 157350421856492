<div *ngIf="quote" class="container">

   <div class="header-box">
      <div class="title">Basket</div>
      <kiosk-cart-status-box class="kiosk-non-interactive" [isUsedInCartPage]="true"></kiosk-cart-status-box>
      <button mat-button [hidden]="quote.isEmpty" (click)="quoteCleared.emit()">Clear</button>
   </div>

   <div class="savings-box" *ngIf="quote.savedAmount > 0">
      <div class="title">You have saved</div>
      <div class="amount">{{ quote.savedAmount | currency}} </div>
   </div>

   <div class="totals-box">
      <div class="title">Total</div>
      <div class="amount">{{(quote.totalAmount || 0) | currency}}</div>
   </div>

   <div class="qrcode-panel" *ngIf="!quote.isEmpty && quote.externalUrl">
      <div class="message">Scan QR code to complete your order now</div>
      <qrcode [qrdata]="quote.externalUrl" [width]="170" [margin]="4"></qrcode>
      <div class="message">Or send email to review and complete later</div>
   </div>

   <div class="form" [class.disabled]="quote.isEmpty">
      <div class="field">
         <span class="title">Customer Email</span>
         <kiosk-keyboard-input #CustomerEmailInput (keyPressed)="handleInputChange(CustomerEmailInput)"></kiosk-keyboard-input>
         <div class="kiosk-error-text">{{customerEmailValidationError}}</div>
      </div>
   </div>

   <div class="action-bar">
      <button mat-button [class.disabled]="quote.isEmpty" (click)="handleCommitAction(null, true)">Send</button>
   </div>
</div>