<kiosk-toolbar [hidden]="!isDataLoaded">
   <kiosk-cart-status-box></kiosk-cart-status-box>
   <kiosk-product-search-box></kiosk-product-search-box>
</kiosk-toolbar>

<div [hidden]="!isDataLoaded" class="catalog-main-content categories">
   <app-category-list-view [categories]="categories"></app-category-list-view>
</div>

<ng-container *ngIf="isNoDataAvailable">
   <div class="kiosk-logo-icon-area"></div>
   <kiosk-warning-chip text="No data available"></kiosk-warning-chip>
</ng-container>

<kiosk-user-interaction-watcher (eventOccured)="trackUserInteraction()"></kiosk-user-interaction-watcher>