export const SVG_ICONS = new Map<string, string>([
   // shopping-cart
   [
      'shopping-cart',
      `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle cx="18.2588" cy="22" r="2" fill="#000000"/>
         <circle cx="6.19434" cy="22" r="2" fill="#000000"/>
         <path d="M2.77419 18L2.08676 17.2738C1.78939 17.5552 1.69366 17.9896 1.84515 18.37C1.99664 18.7504 2.36473 19 2.77419 19V18ZM7 14L7.68744 14.7262L8.13164 14.3058L7.95965 13.7188L7 14ZM3.48387 2L4.44352 1.71881L4.2329 1L3.48387 1L3.48387 2ZM1 1L1.70294e-08 1L-1.70294e-08 3L1 3L1 1ZM3.46163 18.7262L7.68744 14.7262L6.31256 13.2738L2.08676 17.2738L3.46163 18.7262ZM7.95965 13.7188L4.44352 1.71881L2.52422 2.28119L6.04035 14.2812L7.95965 13.7188ZM3.48387 1L1 1L1 3L3.48387 3L3.48387 1ZM2.77419 19H23V17H2.77419V19Z" fill="#000000"/>
         <path d="M4 4H18L17.5 9L6 10" stroke="#000000" stroke-width="2"/>
      </svg>`
   ],
   // slide-right
   [
      'slide-right',
      `<svg viewBox="0 0 15 26" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M3.50195 0L0 0.0363806L5.4696 12.8727L0.381705 26L3.92857 25.9636L9 12.8363L3.50195 0ZM9.50453 0L6 0.0363721L11.4723 12.8697L6.37933 26L9.931 25.9636L15 12.8333L9.50453 0Z" fill="#0D5257"/>
      </svg>`
   ],
   // slide-left
   [
      'slide-left',
      `<svg viewBox="0 0 15 29" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6607 28.9191L15 28.8786L9.78447 14.6012L14.636 -1.93917e-06L11.2539 0.0404622L6.41807 14.6416L11.6607 28.9191Z" fill="#0D5257"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.24199 29L8.58484 28.9595L3.36503 14.6821L8.22301 0.0741863L4.8352 0.11465L5.56076e-05 14.7226L5.24199 29Z" fill="#0D5257"></path>
      </svg>`
   ],
   // slide-down
   [
      'slide-down',
      `<svg viewBox="0 0 15 26" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(90deg); transform-origin: center;">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M3.50195 0L0 0.0363806L5.4696 12.8727L0.381705 26L3.92857 25.9636L9 12.8363L3.50195 0ZM9.50453 0L6 0.0363721L11.4723 12.8697L6.37933 26L9.931 25.9636L15 12.8333L9.50453 0Z" fill="#0D5257"/>
      </svg>`
   ],
   // slide-up
   [
      'slide-up',
      `<svg viewBox="0 0 15 29" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(90deg); transform-origin: center;">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6607 28.9191L15 28.8786L9.78447 14.6012L14.636 -1.93917e-06L11.2539 0.0404622L6.41807 14.6416L11.6607 28.9191Z" fill="#0D5257"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5.24199 29L8.58484 28.9595L3.36503 14.6821L8.22301 0.0741863L4.8352 0.11465L5.56076e-05 14.7226L5.24199 29Z" fill="#0D5257"></path>
      </svg>`
   ],
   // sort
   [
      'sort',
      `<svg viewBox="0 0 24 24">
         <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" stroke="#0d5257"></path>
      </svg>`
   ],
   // filters
   [
      'filters',
      `<svg viewBox="0 0 24 17">
         <path d="M0 4.5H24" stroke="#0d5257" stroke-width="2"/>
         <path d="M0 13H24" stroke="#0d5257" stroke-width="2"/>
         <circle cx="8" cy="4" r="3" fill="white" stroke="#0d5257" stroke-width="2"/>
         <circle cx="17" cy="13" r="3" fill="white" stroke="#0d5257" stroke-width="2"/>
      </svg>`
   ],
   // add-to-cart
   [
      'add-to-cart',
      `<svg viewBox="0 0 48 48" fill="none">
         <rect x="31.125" y="12" width="2.0625" height="10" fill="#333333"/>
         <rect x="37.3125" y="16" width="2" height="10.3125" transform="rotate(90 37.3125 16)" fill="#333333"/>
         <circle cx="29.2578" cy="35" r="2" fill="#333333"/>
         <circle cx="17.1953" cy="35" r="2" fill="#333333"/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M11 14L15.2329 14L19.1316 27.3058L16.2853 30H34V32H13.7742C13.3647 32 12.9966 31.7504 12.8451 31.37C12.6937 30.9896 12.7894 30.5552 13.0868 30.2738L16.8684 26.6942L13.7348 16H11V14Z" fill="#333333"/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M25 18L25 16H15V18H20H25ZM27 18H27.895H29.905L30.105 16H27L27 18ZM27.695 20H29.705L29.4125 22.9244L17.0866 23.9962L16.9134 22.0038L27.5875 21.0756L27.695 20Z" fill="#333333"/>
      </svg>`
   ],
   // delete
   [
      'delete',
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
         <path d="M6 7H18V20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20V7Z" stroke="#333333" stroke-width="2"/>
         <rect x="8" y="3" width="8" height="4" stroke="#333333" stroke-width="2"/>
         <line x1="10" y1="17" x2="10" y2="10" stroke="#333333" stroke-width="2"/>
         <line x1="14" y1="17" x2="14" y2="10" stroke="#333333" stroke-width="2"/>
         <line x1="20" y1="7" x2="4" y2="7" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`
   ],
   // magnifying-glass
   [
      'magnifying-glass',
      `<svg viewBox="0 0 16 16" width="16" height="16" fill="none" preserveAspectRatio="none" role="img">
         <path d="M13.0013 7.33366C13.0013 10.4633 10.4642 13.0003 7.33464 13.0003C4.20502 13.0003 1.66797 10.4633 1.66797 7.33366C1.66797 4.20405 4.20502 1.66699 7.33464 1.66699C10.4642 1.66699 13.0013 4.20405 13.0013 7.33366Z" stroke="#333333" stroke-width="2"></path>
         <rect x="6.66797" y="4.66699" width="1.33333" height="5.33333" fill="#333333"></rect>
         <rect x="10" y="6.66699" width="1.33333" height="5.33333" transform="rotate(90 10 6.66699)" fill="#333333"></rect>
         <path d="M11.292 12L14.6253 15.3333" stroke="#333333" stroke-width="2"></path>
      </svg>`
   ]
]);