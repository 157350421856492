import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppConfig } from "src/environments/defaults";
import { ComponentSharedService, ProductInfo } from "tutch-kiosk-core";
import { ProductLocation, StoreData, StorePlace, StorePlaceUtil } from "../models/store-map";

const SERVICE_NAME = 'StoreMapService';

@Injectable()
export class StoreMapService
{
   //#region - Properties/Events
   //================================================================================
   public get storeData(): StoreData 
   {
      return this._storeData;
   }
   public set storeData(value: StoreData) 
   {
      StorePlaceUtil.resolveLandmarks(value);
      this._storeData = value; 
      //     
      console.log(SERVICE_NAME, 'STORE_DATA_SET', value, StorePlaceUtil.SpecialOrdersDesk);
   }
   private _storeData = <StoreData>{};
   //================================================================================
   public get selectedPlace(): StorePlace | null 
   {
      return this._placeSelectionChangeStream.value;
   }
   public set selectedPlace(value: StorePlace | null) 
   {
      if (this.selectedPlace !== value)
      {
         console.log(SERVICE_NAME, 'STORE_PLACE_SELECTED', value ?? '[NONE]');
         this._placeSelectionChangeStream.next(value);
      }
   }
   public get placeSelectionChangeStream(): Observable<StorePlace | null> 
   {
      return this._placeSelectionChangeStream.asObservable();
   }
   private _placeSelectionChangeStream = new BehaviorSubject<StorePlace | null>(null);
   //================================================================================
   //#endregion

   //#region - Lifecycle  
   //================================================================================
   constructor(private _services: ComponentSharedService) 
   {
      this._services.userInteractionService.interactionSessionEventStream.subscribe((isStarted: boolean | null) => 
      {
         if (isStarted === false)
            this.selectedPlace = null;
      });
   }
   //================================================================================
   //#endregion

   //#region - Configuration
   //================================================================================
   public getMapViewerSiteUrl(): string
   {
      return (this._services.configService.kiosk as AppConfig).mapViewerUrl;
   }
   //================================================================================
   public async getDeviceStoreNumber(): Promise<string>
   {
      const deviceRegistrationInfo = await this._services.deviceService.getDeviceRegistrationInfo();
      return deviceRegistrationInfo.departmentExternalId;
   }
   //================================================================================
   public async getDeviceStoreStartAddress(): Promise<string>
   {
      const deviceRegistrationInfo = await this._services.deviceService.getDeviceRegistrationInfo();
      return deviceRegistrationInfo.attributes.find(attrib => attrib.name == 'StoreMapStartAddress')?.optionValue ?? null;
   }
   //================================================================================
   //#endregion   

   //#region - Locations
   //================================================================================
   public async getProductInStoreLocations(product: ProductInfo): Promise<ProductLocation[] | null>
   {
      const deviceRegistrationInfo = await this._services.deviceService.getDeviceRegistrationInfo();
      const url = `${this._services.configService.kiosk.dotnet.catalogUrl}/products/${product.id}/inStoreLocations`;
      //
      const data = await this._services.dotnetService.getData(deviceRegistrationInfo, url);
      //
      const locations: ProductLocation[] = (data.inStoreLocations ?? []).map((location: any) => 
      {
         const aisle = location.aisle as string;
         const bay = location.bay as string;
         //
         return {
            product,
            address: `${aisle.padStart(3, '0')}.${bay.padStart(3, '0')}`,
            name: `Aisle ${aisle}`
         };
      });
      //
      return locations.length ? locations : null;
   }
   //================================================================================
   //#endregion   
}