<div class="container-outer" (click)="requestAction()">
   <div class="container-inner">
      <div class="image">
         <div class="thumbnail" [kiosk-background-image]="{ url: brochure.thumbnailMedia?.url || 'default', size: 'contain', spinnerSize: '30%', thumbnailOwnerType: 'BROCHURE'}"></div>
      </div>
      <div class="info">
         <div class="name">
            {{ brochure.name }}
         </div>
      </div>
   </div>
</div>