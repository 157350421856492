import { Component } from '@angular/core';
import { BrochureListViewComponentBase } from 'tutch-kiosk-core';

const SLIDES_AREA_COL_COUNT = 6;
const SLIDES_AREA_ROW_COUNT = 2;

@Component({
   selector: 'app-brochure-list-view',
   templateUrl: 'brochure-list-view.component.html',
   styleUrls: ['brochure-list-view.component.scss'],
   providers: [{ provide: BrochureListViewComponentBase, useExisting: BrochureListViewComponent }]
})
export class BrochureListViewComponent extends BrochureListViewComponentBase
{
   public isAutoSlidesPerViewCenteringEnabled(): boolean
   {
      let brochureCount = this.brochures?.length ?? 0;
      return brochureCount <= SLIDES_AREA_COL_COUNT * SLIDES_AREA_ROW_COUNT;
   }   
}