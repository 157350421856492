<div class="container-outer" (click)="requestAction()">
   <div class="container-inner">
      <div class="image">
         <div class="thumbnail" [kiosk-background-image]="{ url: product.thumbnailMedia?.url || 'default', size: 'contain', spinnerSize: '30%', thumbnailOwnerType: 'PRODUCT'}"></div>
      </div>
      <div class="info">
         <div class="name">
            {{ product.name }}
         </div>
         <div class="price">
            <app-product-price [product]="product" [isUsedInProductTile]="true"></app-product-price>
         </div>
      </div>
   </div>
</div>