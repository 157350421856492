import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { TutchKioskCoreModule } from 'tutch-kiosk-core';
import { KioskModule } from '../kiosk/kiosk.module';
import { CheckoutStepQuoteComponent } from './components/checkout-step-quote/checkout-step-quote.component';
import { CheckoutStepOrderComponent } from './components/checkout-step-order/checkout-step-order.component';
import { CheckoutWizardPage } from './pages/checkout-wizard/checkout-wizard.page';
import { QuoteSummaryComponent } from './components/quote-summary/quote-summary.component';
import { QuoteItemListComponent } from './components/quote-item-list/quote-item-list.component';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      QRCodeModule,
      TutchKioskCoreModule,
      KioskModule
   ],
   declarations: [
      CheckoutStepQuoteComponent,
      CheckoutStepOrderComponent,
      QuoteItemListComponent,
      QuoteSummaryComponent,
      CheckoutWizardPage
   ]
})
export class CartModule { }
