<div *ngIf="quote" class="container" [class.is-empty]="quote.isEmpty">
   
   <ng-container *ngIf="quote.isEmpty">
      <div class="empty-cart-warning">Your basket is empty</div>   
      <button mat-button class="empty-cart-button" (click)="navigateBack()">Continue shopping</button>
   </ng-container>

   <div *ngIf="!quote.isEmpty" class="list-box">
      <ng-container *ngFor="let item of quote.items">
         <div class="list-row" [attr.data-product-id]="item.product.id">

            <div class="image-block">
               <div class="thumbnail" [kiosk-background-image]="{ url: item.product.thumbnailMedia?.url || 'default', size: 'contain', thumbnailOwnerType: 'PRODUCT' }"></div>
            </div>

            <div class="main-block">
               <div class="name" [innerHTML]="item.product.name" [style.fontSize.px]="getNameFontSize(item.product.name)"></div>
               <div class="code" *ngIf="item.product.sku || item.product.code">
                  <div *ngIf="item.product.code">Part number: {{item.product.code}}</div>
                  <div *ngIf="item.product.sku">I/N: {{item.product.sku}}</div>
               </div>
               <div class="prices">
                  <div class="price normal" [class.has-special-price]="item.product.specialPrice">
                     <div class="title">Price</div>
                     <span class="value">{{item.product.price | currency}}</span>
                  </div>
                  <div class="price special" *ngIf="item.product.specialPrice">
                     <div class="title">Sale</div>
                     <span class="value">{{item.product.specialPrice | currency}}</span>
                  </div>
               </div>
            </div>

            <div class="quantity-block">
               <div class="title">Quantity</div>
               <kiosk-quantity-editor [value]="item.quantity" [minValue]="1" [maxValue]="item.product.quantityAvailableToOrder"
                  (valueChanged)="setItemQuantity(item, $event)"></kiosk-quantity-editor>
            </div>

            <div class="total-block">
               <div class="title">Total</div>
               <div class="amount">{{item.totalAmount | currency}}</div>
            </div>

            <button mat-button class="remove-button" (click)="requestItemRemoval(item)">
               <mat-icon svgIcon="delete"></mat-icon>
            </button>

         </div>
      </ng-container>
   </div>
</div>