import { Component } from '@angular/core';
import { ProductAttributeOptionListComponentBase, ProductAttributeOption } from 'tutch-kiosk-core';

@Component({
   selector: 'app-product-attribute-option-list',
   templateUrl: 'product-attribute-option-list.component.html',
   styleUrls: ['product-attribute-option-list.component.scss'],
   providers: [{ provide: ProductAttributeOptionListComponentBase, useExisting: ProductAttributeOptionListComponent }]
})
export class ProductAttributeOptionListComponent extends ProductAttributeOptionListComponentBase { }
