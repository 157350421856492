import { Component } from '@angular/core';
import { CheckoutStepOrderComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-checkout-step-order',
   templateUrl: 'checkout-step-order.component.html',
   styleUrls: ['checkout-step-order.component.scss'],
   providers: [{ provide: CheckoutStepOrderComponentBase, useExisting: CheckoutStepOrderComponent }]
})
export class CheckoutStepOrderComponent extends CheckoutStepOrderComponentBase { }
