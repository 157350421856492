import { Component } from '@angular/core';
import { CheckoutWizardPageBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-checkout-wizard-page',
   templateUrl: 'checkout-wizard.page.html',
   styleUrls: ['checkout-wizard.page.scss']
})
export class CheckoutWizardPage extends CheckoutWizardPageBase
{
   public override async handleQuoteCompletedEvent()
   {
      await super.submitOrder();
      this.setCurrentStep(this.WIZARD_STEP.ORDER);
   }
}