import { Component } from '@angular/core';
import { BrochureCategoryListViewComponentBase } from 'tutch-kiosk-core';

const SLIDES_AREA_COL_COUNT = 5;
const SLIDES_AREA_ROW_COUNT = 3;

@Component({
   selector: 'app-brochure-category-list-view',
   templateUrl: 'category-list-view.component.html',
   styleUrls: ['category-list-view.component.scss'],
   providers: [{ provide: BrochureCategoryListViewComponentBase, useExisting: BrochureCategoryListViewComponent }]
})
export class BrochureCategoryListViewComponent extends BrochureCategoryListViewComponentBase 
{
   public get slidesPerColumn(): number
   {
      if (this._categoryCount < SLIDES_AREA_COL_COUNT)
         return 1;
      else if (this._categoryCount <= SLIDES_AREA_COL_COUNT * 2)
         return 2;
      else
         return 3;
   }

   public get itemsPerView(): number
   {
      return this._categoryCount > SLIDES_AREA_COL_COUNT * SLIDES_AREA_ROW_COUNT * 6 
         ? SLIDES_AREA_COL_COUNT * SLIDES_AREA_ROW_COUNT : 0;
   }

   public get isAutoSlidesPerViewCenteringEnabled(): boolean
   {
      return this._categoryCount <= SLIDES_AREA_COL_COUNT * SLIDES_AREA_ROW_COUNT;
   }

   public get slidesScalingFactor(): number
   {
      switch (this._categoryCount)
      {
         case 1:
            return 1.9;
         case 2:
            return 1.7;
         case 3:
            return 1.4;
         case 4: case 5: case 6:
            return 1.3;
         default:
            return 1;
      }
   }

   private get _categoryCount(): number
   {
      return this.categories ? this.categories.length : 0;
   }
}