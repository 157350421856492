// Libraries
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, DetachedRouteHandle, PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

// Core
import
   {
      BrochureTestPage,
      CatalogTestPage,
      ChatBoxComponent,
      DeviceAdminPage,
      DeviceInfoPage,
      DeviceRegisterPage,
      DvmPage,
      DvmSlidesPage,
      DvmTestPage,
      ExternalContentPage,
      ForbiddenPage,
      IsDeviceRegisteredGuard,
      IsTrainingModeGuard,
      IsUserAllowedToAdministerDeviceGuard,
      IsUserAllowedToRegisterDeviceGuard,
      KIOSK_CORE_CONFIG_TOKEN,
      ProductSearchService as KioskProductSearchService,
      MediaTestPage,
      NotFoundPage,
      RedirectPage,
      TutchKioskCoreModule,
      UnauthorisedPage,
      UserInfoPage,
      UserSignInPage
   } from 'tutch-kiosk-core';

// Configuration
import { createKioskCoreConfig, environment } from '../environments/environment';

// Application
import { AppComponent } from './app.component';
//
import { SVG_ICONS } from './kiosk/components/svg-icon/svg-icon.definitions';
import { KioskModule } from './kiosk/kiosk.module';
import { HomePage } from './kiosk/pages/home/home.page';
//
import { CatalogModule } from './catalog/catalog.module';
import { CatalogPage } from './catalog/pages/catalog/catalog.page';
import { CategoryListPage } from './catalog/pages/category-list/category-list.page';
import { CategoryPage } from './catalog/pages/category/category.page';
import { ProductListPage } from './catalog/pages/product-list/product-list.page';
import { ProductPage } from './catalog/pages/product/product.page';
import { ProductSearchService } from './catalog/services/product-search.service';
//
import { CartModule } from './cart/cart.module';
import { CheckoutWizardPage } from './cart/pages/checkout-wizard/checkout-wizard.page';
//
import { BrochureModule } from './brochure/brochure.module';
import { BrochureCategoryListPage } from './brochure/pages/category-list/category-list.page';
import { BrochureCategoryPage } from './brochure/pages/category/category.page';
//
import { MapModule } from './map/map.module';
import { StoreMapPageHost } from './map/pages/store-map/store-map.page';

// Routing
const AUTH_ROUTES: Routes = [
   { path: 'admin', component: UserInfoPage },
   { path: 'signin', component: UserSignInPage },
   { path: 'redirect', component: RedirectPage },
   { path: 'unauthorised', component: UnauthorisedPage },
   { path: 'forbidden', component: ForbiddenPage }
];
const DEVICE_ROUTES: Routes = [
   { path: '', pathMatch: 'full', component: DeviceInfoPage },
   { path: 'register', component: DeviceRegisterPage, canActivate: [IsUserAllowedToRegisterDeviceGuard] },
   { path: 'admin', component: DeviceAdminPage, canActivate: [IsUserAllowedToAdministerDeviceGuard] }
];
const DVM_ROUTES: Routes = [
   { path: '', pathMatch: 'full', component: DvmSlidesPage },
   { path: 'test', component: DvmTestPage, canActivate: [IsTrainingModeGuard] },
   { path: ':id', component: DvmPage },
];
const CATALOG_ROUTES: Routes = [
   { path: '', pathMatch: 'full', component: CatalogPage },
   { path: 'category/:id', component: CategoryPage },
   { path: 'categories', component: CategoryListPage },
   { path: 'product/:id', component: ProductPage },
   { path: 'product/:id/media', component: ProductPage },
   { path: 'product/:id/video', component: ProductPage },
   { path: 'products', component: ProductListPage },
   { path: 'test', component: CatalogTestPage },
];
const BROCHURE_ROUTES: Routes = [
   { path: 'category/:id', component: BrochureCategoryPage },
   { path: 'categories', component: BrochureCategoryListPage },
   { path: 'test', component: BrochureTestPage },
];
const CHAT_ROUTES: Routes = [
   { path: 'box', component: ChatBoxComponent }
];
const CART_ROUTES: Routes = [
   { path: 'checkout', component: CheckoutWizardPage }
];
const MEDIA_ROUTES: Routes = [
   { path: 'test', component: MediaTestPage }
];
const KIOSK_ROUTES: Routes = [
   { path: 'external', component: ExternalContentPage },
   { path: 'map', component: StoreMapPageHost}
];

const ROUTES: Routes = [
   { path: '', pathMatch: 'full', component: HomePage, canActivate: [IsDeviceRegisteredGuard] },
   { path: 'auth', children: AUTH_ROUTES },
   { path: 'device', children: DEVICE_ROUTES },
   { path: 'dvm', children: DVM_ROUTES },
   { path: 'catalog', children: CATALOG_ROUTES },
   { path: 'brochure', children: BROCHURE_ROUTES },
   { path: 'chat', children: CHAT_ROUTES },
   { path: 'cart', children: CART_ROUTES },
   { path: 'media', children: MEDIA_ROUTES },
   { path: 'kiosk', children: KIOSK_ROUTES },
   { path: '**', component: NotFoundPage }
];

@NgModule({
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules, useHash: false, enableTracing: false }),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable:30000' }),
      TutchKioskCoreModule.forRoot(),
      KioskModule,
      BrochureModule,
      CatalogModule,
      CartModule,
      MapModule
   ],
   providers: [
      { provide: KIOSK_CORE_CONFIG_TOKEN, useFactory: createKioskCoreConfig },
      { provide: ProductSearchService },
      { provide: KioskProductSearchService, useExisting: ProductSearchService }
   ],
   declarations: [AppComponent],
   bootstrap: [AppComponent]
})
export class AppModule 
{
   constructor(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer)
   {
      SVG_ICONS.forEach((value, key) => matIconRegistry.addSvgIconLiteral(key, sanitizer.bypassSecurityTrustHtml(value)));
   }
}