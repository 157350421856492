<div class="container">
   <div *ngIf="familyProducts?.length" class="related-section families">
      <div class='title'>Similar products</div>
      <kiosk-slides-carousel 
         [slidesPerView]="familyProducts.length > 1 ? 2 : 1" 
         [isVerticalOrientation]="true"
         [items]="familyProducts"
         [itemTemplate]="ProductTemplate">
      </kiosk-slides-carousel>
   </div>
   <div *ngIf="product.mediaList?.length" class="media-section" [class.has-left-margin]="!familyProducts?.length && relatedProducts?.length">
      <mat-icon class="video" *ngIf="isProductVideoAvailable" (click)="requestVideoFullScreen()">ondemand_video</mat-icon>
      <mat-icon class="fullscreen" (click)="requestMediaFullScreen()" svgIcon="magnifying-glass"></mat-icon>
      <kiosk-product-slides [product]="product" imageSpinnerFit="scale-down"></kiosk-product-slides>
   </div>
   <div class="info-section" [class.has-right-margin]="familyProducts?.length && !relatedProducts?.length" [class.has-extended-width]="!familyProducts?.length && !relatedProducts?.length">
      <div class="badge-text-block" [ngClass]="getBadgeCode()" *ngIf="getBadgeText()">{{getBadgeText()}}</div>
      <div class="main-block">
         <div class="name-block">
            <mat-icon class="video" *ngIf="isProductVideoAvailable && !product.mediaList?.length" (click)="requestVideoFullScreen()">ondemand_video</mat-icon>
            <div class="text" [style.fontSize.px]="getNameFontSize()" [innerHTML]="product.name"></div>
         </div>
         <div class="price-block">
            <app-product-price [product]="product"></app-product-price>
         </div>
         <div class="code-block" *ngIf="product.sku || product.code">
            <div *ngIf="product.code">Part number: {{product.code}}</div>
            <div *ngIf="product.sku">I/N: {{product.sku}}</div>
         </div>
         <div class="badge-info-block" [ngClass]="getBadgeCode()" *ngIf="getBadgeInfo()">{{getBadgeInfo()}}</div>
         <div class="badge-delivery-block" *ngIf="getBadgeDeliveryHtml()" [innerHTML]="getBadgeDeliveryHtml()"></div>
         <mat-tab-group 
            animationDuration="250" 
            [class.is-tab-animation-completed]="isTabAnimationCompleted"
            (selectedTabChange)="isTabAnimationCompleted = false"
            (animationDone)="isTabAnimationCompleted = true"
            class="description-block" *ngIf="product.specificationInfo || product.featuresInfo || product.dimensionInfo">
            <mat-tab label="Description">
               <div class="text" [innerHTML]="product.description"></div>
            </mat-tab>
            <mat-tab label="Specification" *ngIf="product.specificationInfo">
               <div class="text" [innerHTML]="product.specificationInfo"></div>
            </mat-tab>
            <mat-tab label="Features" *ngIf="product.featuresInfo">
               <div class="text" [innerHTML]="product.featuresInfo"></div>
            </mat-tab>
            <mat-tab label="Dimensions" *ngIf="product.dimensionInfo">
               <div class="text" [innerHTML]="product.dimensionInfo"></div>
            </mat-tab>
          </mat-tab-group>         
         <div class="description-block" *ngIf="!product.specificationInfo && !product.featuresInfo && !product.dimensionInfo">
            <div class="text" [innerHTML]="product.description"></div>
         </div>
         <div class="stock-block">
            <!-- TBC: <div class="text" [innerHTML]="getStockHtml()"></div> -->
            <ng-container *ngIf="inStoreLocations">
               <button mat-button *ngFor="let location of inStoreLocations" class="aisle-location-button" (click)="showMap(location)">
                  <mat-icon>place</mat-icon>
                  <span class="name">{{location.name}}</span>
               </button>
            </ng-container>
            <button mat-button class="add-to-cart-button" *ngIf="canAddToCart()" (click)="addToCart()">
               <mat-icon svgIcon="add-to-cart"></mat-icon>
               <span>Add to Basket</span>
            </button>
         </div>         
         <div class="notes-block">
            <span>Prices and available stock levels are updated daily and may vary.</span>
         </div>         
      </div>
   </div>
   <div *ngIf="relatedProducts?.length" class="related-section products">
      <div class='title'>You may also need</div>
      <kiosk-slides-carousel 
         [slidesPerView]="relatedProducts.length > 1 ? 2 : 1" 
         [isVerticalOrientation]="true"
         [items]="relatedProducts"
         [itemTemplate]="ProductTemplate">
      </kiosk-slides-carousel>
   </div>
</div> 

<ng-template #ProductTemplate let-product="item">
   <div class="related-product-tile">
      <app-product-tile [product]="product" (actionRequested)="requestRelatedProductAction(product)"></app-product-tile>
   </div>
</ng-template>
