<div class="header">
   <label (click)="toggleRevealed()">{{attribute.text}}</label>
   <button mat-icon-button [hidden]="!hasSelectedOptions()" (click)="clearSelectedOptions()">
      <mat-icon>refresh</mat-icon>
   </button>
   <button mat-icon-button (click)="toggleRevealed()">
      <mat-icon>{{isRevealed ? 'remove' : 'add'}}</mat-icon>
   </button>
</div>

<div class="list" [hidden]="!isRevealed">
   <div *ngFor="let option of attribute.options" class="item">
      <mat-checkbox [(ngModel)]="option.isSelected" (change)="selectionChanged.emit()">
         {{option.text}} ({{option.data}})
      </mat-checkbox>
   </div>
</div>