import { Component, Input } from '@angular/core';
import { ProductPriceComponentBase, CustomerService } from 'tutch-kiosk-core';

@Component({
   selector: 'app-product-price',
   templateUrl: 'product-price.component.html',
   styleUrls: ['product-price.component.scss'],
   providers: [{ provide: ProductPriceComponentBase, useExisting: ProductPriceComponent }]
})
export class ProductPriceComponent extends ProductPriceComponentBase 
{
   @Input()
   public isUsedInProductTile = false;

   constructor(protected customerService: CustomerService)
   {
      super();
   }
}
