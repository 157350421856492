import { Component, ChangeDetectorRef } from '@angular/core';
import { PriceRangeSelectorComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-price-range-selector',
   templateUrl: 'price-range-selector.component.html',
   styleUrls: ['price-range-selector.component.scss'],
   providers: [{ provide: PriceRangeSelectorComponentBase, useExisting: PriceRangeSelectorComponent }]
})
export class PriceRangeSelectorComponent extends PriceRangeSelectorComponentBase
{
   constructor(changeDetector: ChangeDetectorRef) 
   {
      super(changeDetector);
   }
}