import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AppComponentBase } from 'tutch-kiosk-core';
import { StoreMapPageHost } from './map/pages/store-map/store-map.page';

@Component({
   selector: 'app-root',
   templateUrl: 'app.component.html'
})
export class AppComponent extends AppComponentBase implements AfterViewInit 
{
   @ViewChild('storeMapHostContainer', { read: ViewContainerRef })
   private _storeMapHostContainer!: ViewContainerRef;

   ngAfterViewInit()
   {
      StoreMapPageHost.viewContainerRef = this._storeMapHostContainer;
   }
} 
