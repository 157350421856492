<kiosk-animated-panel>
   <div class="main-panel" [hidden]="selectedBrochure || isBrochureBeingSelected"> 
      <kiosk-slides-carousel
         [slidesPerView]="'auto'" 
         [slidesPerColumn]="2" 
         [isAutoSlidesPerViewCenteringEnabled]="isAutoSlidesPerViewCenteringEnabled()"
         [items]="brochures"
         [itemTemplate]="brochureTemplate"
         [itemsPerView]="12">
      </kiosk-slides-carousel>       
   </div>
   <ng-container *ngIf="selectedBrochure">
      <kiosk-brochure-view [brochure]="selectedBrochure" (viewInitialized)="selectedBrochureViewInitialized.emit()"></kiosk-brochure-view>
   </ng-container>
</kiosk-animated-panel>

<ng-template #brochureTemplate let-brochure="item">
   <app-brochure-tile [brochure]="brochure" (actionRequested)="selectBrochure(brochure)"></app-brochure-tile>
</ng-template>