import { Component } from '@angular/core';
import { CategoryTileComponentBase } from 'tutch-kiosk-core';

@Component({
   selector: 'app-category-tile',
   templateUrl: 'category-tile.component.html',
   styleUrls: ['category-tile.component.scss'],
   providers: [{ provide: CategoryTileComponentBase, useExisting: CategoryTileComponent }]
})
export class CategoryTileComponent extends CategoryTileComponentBase { }
